import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { encuestaActions } from "../handlers/redux";
import DataTable from "src/modules/common/components/DataTable";
import dayjs from "dayjs";
import EtiquetaComponent from "./EtiquetaComponent";
import Modal from "src/modules/common/components/Modal";

const ModalHistorialEtiquetasAsignadas = ({
  encuestaId,
  colaboradorId,
  show,
  onClose,
}) => {
  const dispatch = useDispatch();

  const historial_etiquetas_asignadas = useSelector(
    (state) => state.encuesta.historial_etiquetas_asignadas
  );
  const historial_etiquetas_asignadas_loading = useSelector(
    (state) => state.encuesta.historial_etiquetas_asignadas_loading
  );

  useEffect(() => {
    if (encuestaId && colaboradorId) {
      dispatch(
        encuestaActions.getHistorialEtiquetasAsignadas({
          encuestaId,
          colaboradorId,
        })
      );
    }
  }, []);

  const datatableConfig = {
    headers: [
      {
        title: "Usuario",
        contentClass: "font-bold",
        contentRenderer: (item) => (
          <h1>
            {item.history_user ? item.history_user.username : "Sin usuario"}
          </h1>
        ),
      },
      {
        title: "Etiqueta",
        contentRenderer: (item) => (
          <div className="flex">
            <EtiquetaComponent
              key={item.etiqueta.id}
              name={item.etiqueta.nombre}
              color={item.etiqueta.color}
            />
          </div>
        ),
      },
      {
        title: "Acción",
        contentRenderer: (item) => <span>{item.change_type}</span>,
      },
      {
        title: "Fecha",
        contentRenderer: (item) => (
          <div>
            {item.history_date
              ? dayjs(item.history_date).format("DD/MM/YYYY HH:mm")
              : "Sin fecha"}
          </div>
        ),
      },
    ],
    rowActions: [],
  };

  return (
    <Modal isOpen={show}>
      <div className=" rounded-lg bg-white w-8/12">
        <div className="flex flex-col gap-3 p-4">
          <div className="flex flex-row justify-between text-lg font-bold">
            <div>Historial de etiquetas asignadas</div>
            <div className="cursor-pointer" onClick={onClose}>
              X
            </div>
          </div>
          <DataTable
            containerClass={"max-h-[400px] custom-scrollbar my-8"}
            data={historial_etiquetas_asignadas}
            loading={historial_etiquetas_asignadas_loading}
            config={datatableConfig}
            // Pagination
            totalRecords={historial_etiquetas_asignadas.length}
            //
            checkable={false}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ModalHistorialEtiquetasAsignadas;
