import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { getToken } from "src/utils/helpers";
import { encuestaApis } from "./apis";
import { encuestaActions, encuestaDefault } from "./redux";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { push } from "@lagunovsky/redux-react-router";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import { colaboradorApi } from "src/modules/colaborador/modules/colaborador/handlers/api";

function* getEncuestaSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApis(token).detail, payload);
    yield put(encuestaActions.setEncuesta(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(encuestaActions.setEncuesta(encuestaDefault));
    ErrorToast({ message: "Ocurrio un error al cargar la encuesta." });
  }
}

function* getEncuestaCompleteSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApis(token).detailComplete, payload);
    yield put(encuestaActions.setEncuesta(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    yield put(encuestaActions.setEncuesta(encuestaDefault));
    ErrorToast({ message: "Ocurrio un error al cargar la encuesta." });
  }
}

function* listEncuestaSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApis(token).list, payload);
    yield put(encuestaActions.setEncuestas(data.results));
    yield put(encuestaActions.setEncuestasCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrio un error al cargar las encuestas." });
    yield put(encuestaActions.setEncuestas([]));
  }
}

function* createEncuestaSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApis(token).create, payload);
    yield put(encuestaActions.setEncuestaLoading(false));
    SuccessToast({ message: "Encuesta creada con éxito!" });
    yield put(push("/encuesta/"));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrio un error al crear la encuesta." });
  }
}

function* updateEncuestaSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApis(token).update, payload);
    yield put(encuestaActions.setEncuestaLoading(false));
    SuccessToast({ message: "Encuesta actualizada correctamente!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrio un error al actualizar la encuesta." });
  }
}

function* bulkUpdateEstadosSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApis(token).bulkUpdateEstados, payload);
    SuccessToast({ message: "Estados actualizados con éxito!" });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrio un error al actualizar los estados de las encuestas.",
    });
  } finally {
    yield put(encuestaActions.getEncuestas({}));
    yield put(encuestaActions.resetEncuestasChecked());
  }
}

// Colaboradores seleccionables
function* listColaboradoresSeleccionablesSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      colaboradorApi(token).listEmpleadosSeleccionables,
      payload
    );
    yield put(encuestaActions.setColaboradoresSeleccionables(data.results));
    yield put(encuestaActions.setColaboradoresSeleccionablesCount(data.count));
  } catch (error) {
    console.log(error);
    ErrorToast({ message: "Ocurrio un error al obtener los colaboradores." });
    yield put(encuestaActions.setColaboradoresSeleccionables([]));
    yield put(encuestaActions.setColaboradoresSeleccionablesCount(0));
  }
}

function* bulkDeleteEncuestasSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApis(token).bulkDelete, payload);
    yield put(encuestaActions.getEncuestas({}));
    SuccessToast({ message: data.detail });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrio un error al eliminar las encuestas." });
  }
}

function* colaboradoresAsignadosSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      encuestaApis(token).colaboradoresAsignados,
      payload
    );
    yield put(encuestaActions.setColaboradoresAsignados(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrio un error al obtener los colaboradores asignados.",
    });
    yield put(encuestaActions.setColaboradoresAsignados([]));
  }
}

function* reporteGeneralSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApis(token).reporteGeneral, payload);
    yield put(encuestaActions.setReporteGeneral(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrio un error al obtener el reporte general.",
    });
    yield put(encuestaActions.setReporteGeneral({}));
  }
}

function* reporteResumenSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApis(token).reporteResumen, payload);
    yield put(encuestaActions.setReporteResumen(data.results));
    yield put(encuestaActions.setReporteResumenCount(data.count));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message: "Ocurrio un error al obtener el reporte resumen.",
    });
    yield put(encuestaActions.setReporteResumen([]));
    yield put(encuestaActions.setReporteResumenCount(0));
  }
}

function* reporteColaboradorResumenSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      encuestaApis(token).reporteColaboradorResumen,
      payload
    );
    yield put(encuestaActions.setReporteColaboradorResumen(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({
      message:
        "Ocurrio un error al obtener el resumen del reporte del colaborador.",
    });
    yield put(encuestaActions.setReporteColaboradorResumen({}));
  }
}

function* updateEtiquetasSaga({ payload }) {
  const token = getToken();
  const { encuestaId } = payload;
  try {
    const data = yield call(encuestaApis(token).updateEtiquetas, payload);
    SuccessToast({ message: data.detail });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Error: " + error.response.data.error });
  } finally {
    yield put(encuestaActions.getEncuestaComplete(encuestaId));
    yield put(encuestaActions.getReporteResumen({ encuestaId }));
  }
}

function* deleteEtiquetaSaga({ payload }) {
  const token = getToken();
  const { encuestaId } = payload;
  try {
    const data = yield call(encuestaApis(token).deleteEtiqueta, payload);
    SuccessToast({ message: data.detail });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Error: " + error.response.data.error });
  } finally {
    yield put(encuestaActions.getEncuestaComplete(encuestaId));
  }
}

function* asignarEtiquetasSaga({ payload }) {
  const token = getToken();
  const { encuestaId } = payload;
  try {
    const data = yield call(encuestaApis(token).asignarEtiquetas, payload);
    yield put(encuestaActions.getReporteResumen({ encuestaId }));
    SuccessToast({ message: data.detail });
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrio un error al asignar las etiquetas." });
  }
}

function* exportarReporteResumenSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(encuestaApis(token).exportReporteResumen, payload);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a"); // Crea un enlace temporal
    link.href = url;
    link.setAttribute("download", "asignaciones_exportadas.xlsx"); // Nombre del archivo descargado
    document.body.appendChild(link); // Añade el enlace al DOM
    link.click(); // Simula el clic para descargar el archivo

    // Limpia el enlace creado
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrio un error al exportar el reporte." });
  } finally {
    yield put(encuestaActions.exportarReporteResumenFinish());
  }
}

function* descargarComprobanteRespuestasSaga({ payload }) {
  const token = getToken();
  const { encuestaId, colaboradorId } = payload;
  try {
    const data = yield call(encuestaApis(token).descargarReportePdf, payload);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a"); // Crea un enlace temporal
    link.href = url;
    link.setAttribute(
      "download",
      `comprobante_respuestas_${encuestaId}_${colaboradorId}.pdf`
    ); // Nombre del archivo descargado
    document.body.appendChild(link); // Añade el enlace al DOM
    link.click(); // Simula el clic para descargar el archivo

    // Limpia el enlace creado
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: "Ocurrio un error al descargar el comprobante." });
  } finally {
    yield put(
      encuestaActions.descargarComprobanteRespuestasColaboradorFinish()
    );
  }
}

function* historialEtiquetasAsignadasSaga({ payload }) {
  const token = getToken();
  try {
    const data = yield call(
      encuestaApis(token).historialEtiquetasAsignadas,
      payload
    );
    yield put(encuestaActions.setHistorialEtiquetasAsignadas(data));
  } catch (error) {
    !!process.env.REACT_APP_DEBUG && console.log({ error });
    ErrorToast({ message: error.response.data.error });
    yield put(encuestaActions.setHistorialEtiquetasAsignadas([]));
  }
}

export function* encuestaSagas() {
  // Sección: Encuestas
  yield takeEvery(encuestaActions.getEncuestas.type, listEncuestaSaga);
  yield takeLatest(encuestaActions.setEncuestasFilter.type, listEncuestaSaga);
  yield takeEvery(
    encuestaActions.bulkUpdateEstados.type,
    bulkUpdateEstadosSaga
  );
  yield takeEvery(
    encuestaActions.bulkDeleteEncuestas.type,
    bulkDeleteEncuestasSaga
  );
  // Sección: Encuesta
  yield takeEvery(encuestaActions.createEncuesta.type, createEncuestaSaga);
  yield takeEvery(encuestaActions.updateEncuesta.type, updateEncuestaSaga);
  yield takeEvery(encuestaActions.getEncuesta.type, getEncuestaSaga);
  yield takeEvery(
    encuestaActions.getEncuestaComplete.type,
    getEncuestaCompleteSaga
  );
  // Sección: Colaboradores seleccionables
  yield takeEvery(
    encuestaActions.getColaboradoresSeleccionables.type,
    listColaboradoresSeleccionablesSaga
  );
  yield takeLatest(
    encuestaActions.setColaboradoresSeleccionablesFilter.type,
    listColaboradoresSeleccionablesSaga
  );
  // Sección: Colaboradores asignados
  yield takeEvery(
    encuestaActions.getColaboradoresAsignados.type,
    colaboradoresAsignadosSaga
  );
  // Sección: Reportes
  yield takeEvery(encuestaActions.getReporteGeneral.type, reporteGeneralSaga);
  yield takeEvery(encuestaActions.getReporteResumen.type, reporteResumenSaga);
  yield takeLatest(
    encuestaActions.setReporteResumenFilter.type,
    reporteResumenSaga
  );
  yield takeEvery(encuestaActions.updateEtiquetas.type, updateEtiquetasSaga);
  yield takeEvery(encuestaActions.deleteEtiqueta.type, deleteEtiquetaSaga);
  yield takeEvery(encuestaActions.asignarEtiquetas.type, asignarEtiquetasSaga);
  // Sección: Reporte de un colaborador (al ingresar en una línea de la tabla de reporte resumen)
  yield takeEvery(
    encuestaActions.getReporteColaboradorResumen.type,
    reporteColaboradorResumenSaga
  );
  yield takeEvery(
    encuestaActions.exportarReporteResumen.type,
    exportarReporteResumenSaga
  );
  yield takeEvery(
    encuestaActions.descargarComprobanteRespuestasColaborador.type,
    descargarComprobanteRespuestasSaga
  );
  // Sección: Historial de etiquetas asignadas
  yield takeEvery(
    encuestaActions.getHistorialEtiquetasAsignadas.type,
    historialEtiquetasAsignadasSaga
  );
}
