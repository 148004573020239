import EncuestaConfiguracionPage from "../pages/EncuestaConfiguracionPage";
import EncuestaDetailPage from "../pages/EncuestaDetailPage";
import EncuestaReporteGeneralPage from "../pages/EncuestaReporteGeneralPage";
import EncuestaReportePage from "../pages/EncuestaReportePage";
import EncuestaReporteResumenPage from "../pages/EncuestaReporteResumenPage";
import EncuestaResumenColaboradorPage from "../pages/EncuestaResumenColaboradorPage";
import EncuestasPage from "../pages/EncuestasPage";
import EncuestaVisualizacionPage from "../pages/EncuestaVisualizacionPage";

export const encuestaRoutes = [
  {
    path: "/encuesta/",
    // @ts-ignore
    element: <EncuestasPage />,
  },
  {
    path: "/encuesta/:idEncuesta/",
    // @ts-ignore
    element: <EncuestaDetailPage />,
  },
  {
    path: "/encuesta/:idEncuesta/reporte",
    // @ts-ignore
    element: <EncuestaReportePage />,
  },
  {
    path: "/encuesta/configuracion",
    // @ts-ignore
    element: <EncuestaConfiguracionPage />,
  },
  {
    path: "/encuesta/:encuestaId/configuracion",
    // @ts-ignore
    element: <EncuestaConfiguracionPage />,
  },
  {
    path: "/encuesta/:encuestaId/previsualizar",
    element: <EncuestaVisualizacionPage />,
  },
  {
    path: "/encuesta/:encuestaId/reporte/general",
    element: <EncuestaReporteGeneralPage />,
  },
  {
    path: "/encuesta/:encuestaId/reporte/resumen",
    element: <EncuestaReporteResumenPage />,
  },
  {
    path: "/encuesta/:encuestaId/reporte/resumen/colaborador/:colaboradorId",
    element: <EncuestaResumenColaboradorPage />,
  },
];
