import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const encuestaApis = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    detail: async (id) => {
      const response = await axios.get(URL_BASE + `/encuesta/${id}/detail`, {
        headers,
      });
      return response.data;
    },
    detailComplete: async (id) => {
      const response = await axios.get(
        URL_BASE + `/encuesta/${id}/detail-complete`,
        {
          headers,
        }
      );
      return response.data;
    },
    list: async (params) => {
      const { nombre = "", pagination = {}, ordering = "-fecha_fin" } = params;
      const { page = 1, pageSize = 10 } = pagination;
      const response = await axios.get(
        `${URL_BASE}/encuesta/list/?search=${nombre}&page=${page}&page_size=${pageSize}&ordering=${ordering}`,
        {
          headers,
        }
      );
      return response.data;
    },
    create: async (params) => {
      const response = await axios.post(
        URL_BASE + "/encuesta/create/",
        params,
        {
          headers,
        }
      );
      return response.data;
    },
    update: async (params) => {
      const { encuestaId, encuesta } = params;
      const response = await axios.put(
        URL_BASE + `/encuesta/${encuestaId}/update/`,
        encuesta,
        {
          headers,
        }
      );
      return response.data;
    },
    bulkUpdateEstados: async (params) => {
      const response = await axios.post(
        URL_BASE + "/encuesta/bulk-update-estado/",
        params,
        {
          headers,
        }
      );
      return response.data;
    },
    bulkDelete: async (ids) => {
      const response = await axios.delete(URL_BASE + "/encuesta/bulk-delete/", {
        headers,
        data: { ids: ids },
      });
      return response.data;
    },
    colaboradoresAsignados: async (id) => {
      const response = await axios.get(
        URL_BASE + `/encuesta/${id}/empleados-asignados/`,
        {
          headers,
        }
      );
      return response.data;
    },
    reporteGeneral: async (id) => {
      const response = await axios.get(
        URL_BASE + `/encuesta/${id}/reporte/general/`,
        {
          headers,
        }
      );
      return response.data;
    },
    reporteResumen: async (params) => {
      const {
        encuestaId,
        nombre = "",
        pagination = {},
        opciones_respuestas = [],
        respuesta_texto = "",
        etiquetas = [],
      } = params;
      const { page = 1, pageSize = 50 } = pagination;
      // Generar endpoint
      let endpoint = `/encuesta/${encuestaId}/reporte/resumen/respuestas/?search=${nombre}&page=${page}&page_size=${pageSize}`;
      // Agregar filtros si tiene
      if (opciones_respuestas.length > 0) {
        const opcionesRespuestasParam = opciones_respuestas.join(","); // Tiene que pasarse los ids de las opciones separados por comas sin espacios. Ej: "134,15,341"
        endpoint = endpoint + `&opciones_respuestas=${opcionesRespuestasParam}`;
      }
      if (respuesta_texto) {
        endpoint = endpoint + `&respuesta_texto=${respuesta_texto}`;
      }
      if (etiquetas.length > 0) {
        const etiquetasParam = etiquetas.join(",");
        endpoint = endpoint + `&etiquetas=${etiquetasParam}`;
      }
      //
      const response = await axios.get(URL_BASE + endpoint, {
        headers,
      });
      return response.data;
    },
    reporteColaboradorResumen: async (params) => {
      const { encuestaId, colaboradorId } = params;
      const response = await axios.get(
        URL_BASE +
          `/encuesta/${encuestaId}/reporte/resumen/colaborador/${colaboradorId}/detail/`,
        {
          headers,
        }
      );
      return response.data;
    },
    updateEtiquetas: async (params) => {
      const { encuestaId, etiquetas } = params;
      const response = await axios.post(
        URL_BASE + `/encuesta/${encuestaId}/etiquetas/`,
        etiquetas,
        {
          headers,
        }
      );
      return response.data;
    },
    asignarEtiquetas: async (params) => {
      const { asignacionId, etiquetasIds } = params;
      const request = {
        asignacion_id: asignacionId,
        etiquetas_ids: etiquetasIds,
      };
      const response = await axios.post(
        URL_BASE + `/encuesta/asignacion-etiquetas/`,
        request,
        {
          headers,
        }
      );
      return response.data;
    },
    deleteEtiqueta: async (params) => {
      const { encuestaId, etiquetaId } = params;
      const response = await axios.delete(
        URL_BASE + `/encuesta/${encuestaId}/etiqueta/${etiquetaId}/delete/`,
        {
          headers,
        }
      );
      return response.data;
    },
    exportReporteResumen: async (params) => {
      const { encuestaId, asignacionesIds } = params;
      const request = {
        asignacion_ids: asignacionesIds,
      };
      const response = await axios.post(
        URL_BASE + `/encuesta/${encuestaId}/exportar-reporte-resumen/`,
        request,
        {
          headers,
          responseType: "blob",
        }
      );
      return response.data;
    },
    descargarReportePdf: async (params) => {
      const { encuestaId, colaboradorId } = params;
      const response = await axios.get(
        URL_BASE +
          `/encuesta/${encuestaId}/colaborador/${colaboradorId}/descargar-comprobante-respuestas/`,
        {
          headers,
          responseType: "blob",
        }
      );
      return response.data;
    },
    historialEtiquetasAsignadas: async (params) => {
      const { encuestaId, colaboradorId } = params;
      const response = await axios.get(
        URL_BASE +
          `/encuesta/${encuestaId}/colaborador/${colaboradorId}/historial-etiquetas-asignadas/`,
        {
          headers,
        }
      );
      return response.data;
    },
  };
};
