import axios from "axios";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const feedbackApis = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    createFeedback: async (params) => {
      const url = URL_BASE + "/feedback/create";
      try {
        const response = await axios.post(url, params, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    updateFeedback: async (params) => {
      const { id } = params;
      const url = `${URL_BASE}/feedback/${id}/update`;
      try {
        const response = await axios.put(url, params, { headers });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    listFeedbacks: async (feedbacks_filter) => {
      const { nombre = "", pagination = {}, ordering = "" } = feedbacks_filter;
      const { page = 1, pageSize = 10 } = pagination;
      let url = `${URL_BASE}/feedback/general?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (ordering) {
        url = url + `&ordering=${ordering}`;
      }
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("get feedbacks error", error);
        throw error;
      }
    },
    detailFeedback: async (idFeedback) => {
      try {
        const response = await axios.get(
          URL_BASE + `/feedback/detail/${idFeedback}/`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    jefesDirectos: async (jefes_directos_filter) => {
      const {
        nombre = "",
        pagination = {},
        area,
        departamento,
        sucursal,
      } = jefes_directos_filter;
      const { page = 1, pageSize = 1000 } = pagination;
      let endpoint = `/feedback/jefes-directos?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (area) {
        endpoint = endpoint + `&area_id=${area}`;
      }
      if (departamento) {
        endpoint = endpoint + `&departamento_id=${departamento}`;
      }
      if (sucursal) {
        endpoint = endpoint + `&sucursal_id=${sucursal}`;
      }
      try {
        const response = await axios.get(`${URL_BASE}${endpoint}`, {
          headers,
        });
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("jefesDirectos", error);
        throw error;
      }
    },
    jefesDirectosList: async (params) => {
      const { idFeedback, jefes_directos_list_filter } = params;
      const {
        nombre = "",
        pagination = {},
        area,
        departamento,
        sucursal,
      } = jefes_directos_list_filter;
      const { page = 1, pageSize = 50 } = pagination;
      let endpoint = `/feedback/${idFeedback}/jefes-directos-list?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (area) {
        endpoint = endpoint + `&area_id=${area}`;
      }
      if (departamento) {
        endpoint = endpoint + `&departamento_id=${departamento}`;
      }
      if (sucursal) {
        endpoint = endpoint + `&sucursal_id=${sucursal}`;
      }
      try {
        const response = await axios.get(`${URL_BASE}${endpoint}`, {
          headers,
        });
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("jefesDirectosList", error);
        throw error;
      }
    },
    colaboradores: async (params) => {
      const { idFeedback, idJefeDirecto, colaboradores_filter } = params;
      const {
        nombre = "",
        pagination = {},
        area,
        departamento,
        sucursal,
      } = colaboradores_filter;
      const { page = 1, pageSize = 10 } = pagination;
      let endpoint = `/feedback/${idFeedback}/jefe-directo/${idJefeDirecto}/colaboradores?search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (area) {
        endpoint = endpoint + `&area_id=${area}`;
      }
      if (departamento) {
        endpoint = endpoint + `&departamento_id=${departamento}`;
      }
      if (sucursal) {
        endpoint = endpoint + `&sucursal_id=${sucursal}`;
      }
      try {
        const response = await axios.get(`${URL_BASE}${endpoint}`, {
          headers,
        });
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("jefesDirectos", error);
        throw error;
      }
    },
    detailColaboradorFeedback: async (idColaboradorFeedback) => {
      try {
        const response = await axios.get(
          URL_BASE + `/feedback/colaborador/${idColaboradorFeedback}/detail`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    detailFeedbackNomina: async (params) => {
      const { idFeedback, idJefeDirecto, idColaborador } = params;
      try {
        const response = await axios.get(
          URL_BASE +
            `/feedback/${idFeedback}/jefe_directo/${idJefeDirecto}/colaborador/${idColaborador}/detail`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    createFeedbackNomina: async (params) => {
      const { idFeedback, idJefeDirecto, idColaborador, feedback_nomina } =
        params;
      try {
        const response = await axios.post(
          URL_BASE +
            `/feedback/${idFeedback}/jefe_directo/${idJefeDirecto}/colaborador/${idColaborador}/create`,
          feedback_nomina,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    bulkDeleteFeedback: async (ids) => {
      try {
        const response = await axios.delete(
          URL_BASE + `/feedback/bulk-delete`,
          {
            headers: headers,
            data: { ids: ids },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    bulkDeleteJefesDirectosFeedback: async (params) => {
      try {
        const response = await axios.delete(
          URL_BASE + `/feedback/bulk-delete/jefes-directos`,
          {
            headers: headers,
            data: params,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    bulkDeleteColaboradoresFeedback: async (params) => {
      try {
        const response = await axios.delete(
          URL_BASE + `/feedback/bulk-delete/colaboradores`,
          {
            headers: headers,
            data: params,
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    kpisColaborador: async (idColaborador) => {
      try {
        const response = await axios.get(
          URL_BASE + `/feedback/colaborador/${idColaborador}/kpis-colaborador`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    competenciasGenerales: async (idFeedback) => {
      try {
        const response = await axios.get(
          URL_BASE + `/feedback/${idFeedback}/competencias-generales`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    competenciasAreas: async (params) => {
      const { idFeedback, idColaborador } = params;
      try {
        const response = await axios.get(
          URL_BASE +
            `/feedback/${idFeedback}/colaborador/${idColaborador}/competencias-areas`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    competenciasCargos: async (params) => {
      const { idFeedback, idColaborador } = params;
      try {
        const response = await axios.get(
          URL_BASE +
            `/feedback/${idFeedback}/colaborador/${idColaborador}/competencias-cargos`,
          {
            headers,
          }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    listFeedbacksPorJefe: async (feedbacks_por_jefe_filter) => {
      const { nombre = "", pagination = {} } = feedbacks_por_jefe_filter;
      const { page = 1, pageSize = 10 } = pagination;
      let url = `${URL_BASE}/feedback/por-jefe-directo?search=${nombre}&page=${page}&page_size=${pageSize}`;
      try {
        const response = await axios.get(url, { headers });
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("get feedbacks por jefe error", error);
        throw error;
      }
    },
    cambiarJefeDirecto: async (params) => {
      let url = `${URL_BASE}/feedback/cambiar-jefe-directo`;
      try {
        const response = await axios.post(url, params, { headers });
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("cambiarJefeDirecto error", error);
        throw error;
      }
    },
  };
};
