import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";

const EtiquetaComponent = (props) => {
  const { key, name, color, onClick, onDelete, asignacion_data = null } = props;
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const menuRef = useRef(null);

  // Manejador del clic derecho (solo si onDelete existe)
  const handleContextMenu = (e) => {
    if (onDelete) {
      e.preventDefault(); // Evita el menú contextual predeterminado del navegador
      setMenuPosition({ x: e.pageX, y: e.pageY }); // Guarda la posición del menú
      setMenuVisible(true);
    }
  };

  // Ocultar el menú al hacer clic fuera
  useEffect(() => {
    if (!onDelete) return; // Salir del efecto si onDelete no existe
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuVisible(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  let textColor = "";
  // Si la etiqueta es blanca se muestra el texto en negro, si no se muestra en blanco
  if (color === "#FFFFFF" || color === "#ffffff") {
    textColor = "text-black";
  } else {
    textColor = "text-white";
  }

  return (
    <>
      <div
        key={key}
        className={`border rounded-md px-1 ${
          !!onClick ? "cursor-pointer hover:opacity-75" : ""
        } ${textColor}`}
        style={{ backgroundColor: color }}
        onClick={onClick || null}
        onContextMenu={handleContextMenu} // Solo va a funcionar si onDelete existe
        title={
          asignacion_data
            ? `Asignado por: ${asignacion_data.creado_por} el ${dayjs(
                asignacion_data.fecha_creacion
              ).format("DD/MM/YYYY")}`
            : name
        }
      >
        {name}
      </div>

      {/* Menú contextual personalizado (solo si onDelete existe) */}
      {onDelete && menuVisible && (
        <ul
          ref={menuRef}
          className="absolute bg-white shadow-md rounded-md border p-2"
          style={{ top: menuPosition.y, left: menuPosition.x }}
        >
          <li
            className="cursor-pointer px-2 py-1 hover:bg-gray-200"
            onClick={() => {
              onDelete(); // Llama a la función de eliminación
              setMenuVisible(false); // Oculta el menú
            }}
          >
            Eliminar
          </li>
        </ul>
      )}
    </>
  );
};

export default EtiquetaComponent;
