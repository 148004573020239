import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { encuestaActions, encuestaDefault } from "../handlers/redux";
import { validateDataEncuesta } from "../helpers/helper";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import EncuestaDatosAlcance from "./EncuestaDatosAlcance";
import EncuestaPreguntas from "./EncuestaPreguntas";
import { ArrowLeft, EyeFill, Window } from "react-bootstrap-icons";
import { commonActions } from "src/modules/common/handler/redux";

const EncuestaConfiguracionPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { encuestaId } = params;

  const encuestaDetail = useSelector((state) => state.encuesta.encuesta);
  const encuestaLoading = useSelector(
    (state) => state.encuesta.encuesta_loading
  );
  const colaboradores_seleccionables_loading = useSelector(
    (state) => state.encuesta.colaboradores_seleccionables_loading
  );
  const colaboradores_asignados_loading = useSelector(
    (state) => state.encuesta.colaboradores_asignados_loading
  );

  const [encuesta, setEncuesta] = useState(encuestaDefault);
  const [pantallaActual, setPantallaActual] = useState(1); // Control de pantalla

  useEffect(() => {
    dispatch(commonActions.showSidebar(false));
    dispatch(commonActions.setTitle("Admin"));
    dispatch(commonActions.setTitleMenu([]));
    if (encuestaId) {
      dispatch(encuestaActions.getEncuesta(encuestaId));
      dispatch(encuestaActions.getColaboradoresAsignados(encuestaId));
    }
    dispatch(encuestaActions.getColaboradoresSeleccionables({}));
  }, []);

  useEffect(() => {
    if (encuestaId) {
      setEncuesta(encuestaDetail);
    }
  }, [encuestaDetail]);

  const handleAddPregunta = (index = null) => {
    const nuevaPregunta = encuestaDefault.preguntas[0];

    if (index !== null) {
      // Si viene un index, significa que estamos insertando una pregunta en medio de dos preguntas
      const preguntasCopy = JSON.parse(JSON.stringify(encuesta.preguntas));
      preguntasCopy.splice(index - 1, 0, nuevaPregunta);
      setEncuesta({
        ...encuesta,
        preguntas: preguntasCopy,
      });
      return;
    }

    setEncuesta({
      ...encuesta,
      preguntas: [...encuesta.preguntas, nuevaPregunta],
    });
  };

  const handleAddOpcion = (index) => {
    const encuestaCopy = JSON.parse(JSON.stringify(encuesta));
    encuestaCopy.preguntas[index - 1].opciones.push({ texto: "" });
    setEncuesta(encuestaCopy);
  };

  const handleRemoveOpcion = (index, opcionIndex) => {
    console.log(index, opcionIndex);
    const encuestaCopy = JSON.parse(JSON.stringify(encuesta));
    encuestaCopy.preguntas[index - 1].opciones = encuestaCopy.preguntas[
      index - 1
    ].opciones.filter((_, i) => i !== opcionIndex);
    setEncuesta(encuestaCopy);
  };

  const handleRemovePregunta = (index) => {
    console.log(index);
    setEncuesta({
      ...encuesta,
      preguntas: encuesta.preguntas.filter((_, i) => i !== index - 1),
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "alcance_total") {
      setEncuesta({
        ...encuesta,
        [name]: value === "completo" ? true : false,
      });
      return;
    }
    setEncuesta({
      ...encuesta,
      [name]: value,
    });
  };

  const handleInputChangePregunta =
    (index, opcionIndex = null) =>
    (e) => {
      const { name, value } = e.target;
      const encuestaCopy = JSON.parse(JSON.stringify(encuesta));
      if (opcionIndex !== null) {
        // Si viene una opcionIndex, significa que estamos editando una opcion
        encuestaCopy.preguntas[index - 1].opciones[opcionIndex].texto = value;
      } else {
        encuestaCopy.preguntas[index - 1][name] = value;
        if (
          name === "tipo_respuesta" &&
          value !== "SIMPLE" &&
          value !== "MULTIPLE"
        ) {
          // Si el tipo de respuesta no es de selección, limpiamos las opciones
          encuestaCopy.preguntas[index - 1].opciones = [];
        } else if (
          name === "tipo_respuesta" &&
          (value === "SIMPLE" || value === "MULTIPLE")
        ) {
          encuestaCopy.preguntas[index - 1].opciones = [{ texto: "" }];
        }
      }
      setEncuesta(encuestaCopy);
    };

  const onSubmit = () => {
    const emptyFields = validateDataEncuesta(encuesta);
    if (emptyFields.length > 0) {
      emptyFields.forEach((field) => ErrorToast({ message: field }));
      return;
    }

    const encuestaCopy = {
      ...encuesta,
      preguntas: encuesta.preguntas.map((pregunta, index) => ({
        ...pregunta,
        orden: index + 1, // El índice empieza en 0, por lo que sumamos 1
      })),
    };

    if (encuestaId) {
      dispatch(
        encuestaActions.updateEncuesta({ encuestaId, encuesta: encuestaCopy })
      );
    } else {
      dispatch(encuestaActions.createEncuesta(encuestaCopy));
    }
  };

  const onBack = () => {
    if (pantallaActual === 1) {
      navigate("/encuesta/");
    } else if (pantallaActual === 2) {
      setPantallaActual(1);
    }
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center gap-2 my-4">
        <button onClick={onBack}>
          <ArrowLeft size={24} />
        </button>
        <div className="flex w-2/3 justify-between px-4">
          <h1 className="text-xl font-bold">Configuración de encuesta</h1>
          {!!encuestaId && pantallaActual === 2 ? (
            <button
              className="flex flex-row gap-1 items-center text-sky-500"
              onClick={() => navigate(`/encuesta/${encuestaId}/previsualizar`)}
            >
              <EyeFill size={24} /> Previsualizar encuesta
            </button>
          ) : (
            <div></div>
          )}
        </div>
        {/* Icono ficticio para igualar el espacio por izqiuerda y por derecha */}
        <ArrowLeft size={24} color="transparent" />{" "}
      </div>
      <div className="flex justify-center">
        <div className="flex w-2/3">
          {!encuestaLoading && (
            <div className="flex flex-col w-full">
              {pantallaActual === 1 ? (
                <EncuestaDatosAlcance
                  encuesta={encuesta}
                  handleInputChange={handleInputChange}
                />
              ) : (
                <EncuestaPreguntas
                  encuesta={encuesta}
                  handleAddPregunta={handleAddPregunta}
                  handleInputChangePregunta={handleInputChangePregunta}
                  handleRemovePregunta={handleRemovePregunta}
                  handleAddOpcion={handleAddOpcion}
                  handleRemoveOpcion={handleRemoveOpcion}
                />
              )}
              <div>{colaboradores_asignados_loading}</div>
              <div>{colaboradores_seleccionables_loading}</div>
              {!colaboradores_asignados_loading &&
                !colaboradores_seleccionables_loading && (
                  <div className="flex justify-between my-4">
                    {pantallaActual === 1 && (
                      <button
                        className="text-white text-sm bg-sky-500 w-full h-10 px-4 rounded"
                        onClick={() => setPantallaActual(pantallaActual + 1)}
                      >
                        Configurar preguntas
                      </button>
                    )}
                    {pantallaActual === 2 && (
                      <button
                        className="text-white text-sm bg-sky-500 w-full h-10 px-4 rounded"
                        onClick={onSubmit}
                      >
                        Guardar
                      </button>
                    )}
                  </div>
                )}
            </div>
          )}
          {encuestaLoading && (
            <div className="flex items-center justify-center w-full h-[60vh]">
              <LoadingSpinButton message="Verificando datos, por favor espere..." />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EncuestaConfiguracionPage;
