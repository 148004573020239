import React from "react";

const DownloadingFileAnimation = ({ name = "Descargando..." }) => {
  return (
    <>
      <button
        disabled={true}
        className="flex items-center gap-2 bg-sky-400 text-white px-3 py-2 rounded animated-pulse"
      >
        <span className="font-semibold text-sm">{name}</span>
      </button>
    </>
  );
};

export default DownloadingFileAnimation;
