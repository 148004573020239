import React from "react";

export const renderEstadoEncuesta = (estado) => {
  const style = `inline-flex items-center rounded-md px-2 py-1 text-sm font-medium text-white ring-1 ring-inset ring-gray-500/10 ${
    estado ? "bg-green-500" : "bg-red-500"
  }`;
  return <span className={style}>{estado ? "Abierta" : "Cerrada"}</span>;
};

export const validateDataEncuesta = (encuesta) => {
  /* Se valida que todos los datos de la encuesta esten correctamente cargados. */
  const emptyFields = [];

  if (!encuesta.nombre) emptyFields.push("Nombre de la encuesta");
  if (!encuesta.fecha_inicio) emptyFields.push("Fecha de inicio");
  if (!encuesta.fecha_fin) emptyFields.push("Fecha de fin");

  const { preguntas } = encuesta;
  if (preguntas.length === 0) {
    emptyFields.push("Preguntas de la encuesta");
  } else {
    preguntas.forEach((pregunta, index) => {
      if (!pregunta.texto)
        emptyFields.push(`Pregunta ${index + 1} - Desarrollo de la pregunta`);
      if (!pregunta.tipo_respuesta)
        emptyFields.push(`Pregunta ${index + 1} - Tipo de respuesta`);
      if (
        pregunta.opciones.length === 0 &&
        (pregunta.tipo_respuesta === "SIMPLE" ||
          pregunta.tipo_respuesta === "MULTIPLE")
      ) {
        emptyFields.push(`Pregunta ${index + 1} - Opciones de la pregunta`);
      } else if (
        pregunta.opciones.length > 0 &&
        (pregunta.tipo_respuesta === "SIMPLE" ||
          pregunta.tipo_respuesta === "MULTIPLE")
      ) {
        pregunta.opciones.forEach((opcion, opcionIndex) => {
          if (!opcion.texto)
            emptyFields.push(
              `Pregunta ${index + 1} - Opcion ${
                opcionIndex + 1
              } - Desarrollo de la opcion`
            );
        });
      }
    });
  }

  if (emptyFields.length === 0) {
    if (encuesta.fecha_fin < encuesta.fecha_inicio) {
      emptyFields.push("Fecha de fin no puede ser menor a la fecha de inicio");
    }
  }

  return emptyFields;
};