import React, { useEffect } from "react";
import EncuestaReportePage from "./EncuestaReportePage";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { encuestaActions } from "../handlers/redux";
import userDefault from "src/modules/common/components/images/user_default.png";
import dayjs from "dayjs";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";

const EncuestaReporteGeneralPage = () => {
  const dispatch = useDispatch();
  const { encuestaId } = useParams();

  const reporte_general = useSelector(
    (state) => state.encuesta.reporte_general
  );
  const reporte_general_loading = useSelector(
    (state) => state.encuesta.reporte_general_loading
  );
  const { preguntas = [] } = reporte_general;

  const cantidadPerfiles = 3; // La cantidad de fotos de empleado que se van a mostrar

  useEffect(() => {
    if (!encuestaId) return;
    dispatch(encuestaActions.getReporteGeneral(encuestaId));
  }, []);

  return (
    <EncuestaReportePage>
      {!reporte_general_loading ? (
        <div className="flex justify-center">
          <div className="w-2/3 px-4">
            {preguntas.map((pregunta, index) => (
              <div
                key={`pregunta-${index}`}
                className="border rounded-2xl p-4 mb-4 shadow-sm"
              >
                <h2 className="text-sm font-semibold mb-2">
                  Pregunta {index + 1}
                </h2>
                <p className="mb-2">{pregunta.texto}</p>
                <div className="max-h-[520px] custom-scrollbar">
                  {(pregunta.tipo_respuesta === "SIMPLE" ||
                    pregunta.tipo_respuesta === "MULTIPLE") && (
                    <div className="flex flex-col gap-2">
                      {pregunta.respuestas.map((respuesta, respuestaIndex) => (
                        <div
                          key={`respuesta-${respuestaIndex}`}
                          className="flex justify-between items-center border rounded-md py-1 px-2 gap-2"
                        >
                          {/* Texto de la opción */}
                          <div className="w-1/2">{respuesta.opcion}</div>
                          {/* Contenedor de la parte derecha */}
                          <div className="flex flex-row gap-2 text-sm items-center w-1/2 justify-end">
                            <div className="flex flex-row align-middle gap-1">
                              {respuesta.votos
                                .slice(0, cantidadPerfiles)
                                .map((voto, votoIndex) => (
                                  <img
                                    key={`voto-${votoIndex}`}
                                    src={voto.foto_empleado || userDefault}
                                    alt={`${voto.nombre} ${voto.apellido}`}
                                    title={`${voto.nombre} ${voto.apellido}`}
                                    className="w-6 h-6 rounded-full"
                                  />
                                ))}
                              {respuesta.votos.length > cantidadPerfiles && (
                                <div
                                  className="flex justify-center items-center w-6 h-6 rounded-full bg-gray-200 text-black"
                                  title={`${
                                    respuesta.votos.length - cantidadPerfiles
                                  } ${
                                    respuesta.votos.length -
                                      cantidadPerfiles ===
                                    1
                                      ? "colaborador más"
                                      : "colaboradores más"
                                  }`}
                                >
                                  +{respuesta.votos.length - cantidadPerfiles}
                                </div>
                              )}
                            </div>
                            <div className="flex items-center gap-2">
                              <div className="flex justify-center bg-gray-200 rounded-lg px-2 w-[170px] align-center">
                                {`${respuesta.votos.length}/${reporte_general.total_asignados} respondieron`}
                              </div>
                              <div className="flex justify-center bg-gray-200 rounded-lg px-2 w-[80px] align-center">
                                {respuesta.porcentaje}%
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {pregunta.tipo_respuesta === "TEXTO" && (
                    <div className="flex flex-col gap-2">
                      {pregunta.respuestas.map((respuesta, respuestaIndex) => (
                        <div className="flex justify-between items-center border rounded-md py-1 px-2">
                          <div className="w-2/3 p-2 whitespace-pre-line">
                            {respuesta.respuesta_texto}
                          </div>
                          <div className="flex flex-row gap-2">
                            <img
                              key={`voto-${respuestaIndex}`}
                              src={
                                respuesta.empleado.foto_empleado || userDefault
                              }
                              alt={`${respuesta.empleado.nombre} ${respuesta.empleado.apellido}`}
                              title={`${respuesta.empleado.nombre} ${respuesta.empleado.apellido}`}
                              className="w-6 h-6 rounded-full"
                            />
                            <span>{`${respuesta.empleado.nombre} ${respuesta.empleado.apellido}`}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {pregunta.tipo_respuesta === "FECHA" && (
                    <div className="flex flex-col gap-2">
                      {pregunta.respuestas.map((respuesta, respuestaIndex) => (
                        <div className="flex justify-between items-center border rounded-md py-1 px-2">
                          <div className="w-2/3 p-2">
                            {dayjs(respuesta.respuesta_fecha).format(
                              "DD/MM/YYYY HH:mm"
                            )}
                          </div>
                          <div className="flex flex-row gap-2">
                            <img
                              key={`voto-${respuestaIndex}`}
                              src={
                                respuesta.empleado.foto_empleado || userDefault
                              }
                              alt={`${respuesta.empleado.nombre} ${respuesta.empleado.apellido}`}
                              title={`${respuesta.empleado.nombre} ${respuesta.empleado.apellido}`}
                              className="w-6 h-6 rounded-full"
                            />
                            <span>{`${respuesta.empleado.nombre} ${respuesta.empleado.apellido}`}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-full">
          <LoadingSpinButton message="Cargando reporte..." />
        </div>
      )}
    </EncuestaReportePage>
  );
};

export default EncuestaReporteGeneralPage;
