import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { encuestaActions } from "../handlers/redux";
import { SearchInput } from "src/modules/permiso/components/SearchInput";
import MostrarPaginas from "src/modules/common/components/MostrarPaginas";
import DataTable from "src/modules/common/components/DataTable";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { renderEstadoEncuesta } from "../helpers/helper";
import { PrimaryButton } from "src/modules/common/components/PrimaryButton";
import { ChevronRight } from "react-bootstrap-icons";
import ReactSelect from "react-select";
import { commonActions } from "src/modules/common/handler/redux";
import { WarningToast } from "src/modules/common/components/WarningToast";

function EncuestasPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const encuestas = useSelector((state) => state.encuesta.encuestas);
  const encuestas_loading = useSelector(
    (state) => state.encuesta.encuestas_loading
  );
  const encuestas_count = useSelector(
    (state) => state.encuesta.encuestas_count
  );
  const encuestas_filter = useSelector(
    (state) => state.encuesta.encuestas_filter
  );
  const encuestas_checked = useSelector(
    (state) => state.encuesta.encuestas_checked
  );

  const [nombre, setNombre] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    dispatch(commonActions.showSidebar(true));
    dispatch(commonActions.setTitle("Encuestas"));
    dispatch(commonActions.setTitleMenu([]));
    dispatch(encuestaActions.getEncuestas({}));
  }, []);

  useEffect(() => {
    dispatch(
      encuestaActions.setEncuestasFilter({
        ...encuestas_filter,
        nombre,
        pagination: {
          page: 1,
          pageSize: pageSize,
        },
      })
    );
  }, [nombre]);

  const goToReporte = (encuestaId) => {
    navigate(`/encuesta/${encuestaId}/reporte/general`);
  };

  const datatableConfig = {
    headers: [
      {
        title: "Creadas",
        contentClass: "font-bold",
        // onOrder: setOrderingPermisos("empleado"),
        // accessor: "nombre",
        contentRenderer: (item) => (
          <div
            className="flex flex-row gap-2 items-center p-2 cursor-pointer"
            onClick={() => goToEncuesta(item)}
          >
            <h1 className="font-bold">{item.nombre}</h1>
            {/* @ts-ignore */}
            <ChevronRight color="#0ea4e9" />
          </div>
        ),
      },
      {
        title: "Inicio",
        // accessor: "fecha_inicio",
        // onOrder: setOrderingPermisos("tipo_permiso"),
        contentRenderer: (item) => (
          <div>
            {item.fecha_inicio
              ? dayjs(item.fecha_inicio).format("DD/MM/YYYY HH:mm")
              : "Sin fecha"}
          </div>
        ),
      },
      {
        title: "Finalización",
        accessor: "fecha_fin",
        // onOrder: setOrderingPermisos("estado"),
        contentRenderer: (item) => (
          <div>
            {item.fecha_fin
              ? dayjs(item.fecha_fin).format("DD/MM/YYYY HH:mm")
              : "Sin fecha"}
          </div>
        ),
      },
      {
        title: "Encuestados",
        // accessor: "estado",
        // onOrder: setOrderingPermisos("estado"),
        contentRenderer: (item) => {
          return (
            <div>
              {item.encuestados.completados}/{item.encuestados.asignados}
            </div>
          );
        },
      },
      {
        title: "Estado",
        // accessor: "estado",
        // onOrder: setOrderingPermisos("estado"),
        contentRenderer: (item) => {
          return <div>{renderEstadoEncuesta(item.estado)}</div>;
        },
      },
      {
        title: "Configuración",
        // accessor: "estado",
        // onOrder: setOrderingPermisos("estado"),
        contentRenderer: (item) => {
          return (
            <button
              className="h-10 text-sm px-4 rounded border border-gray-500"
              onClick={() => goToReporte(item.id)}
            >
              Ver reporte
            </button>
          );
        },
      },
    ],
    rowActions: [
      {
        label: "Cerrar encuesta",
        action: (item) => {
          dispatch(
            encuestaActions.bulkUpdateEstados({ ids: [item.id], estado: false })
          );
        },
      },
    ],
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      encuestaActions.setEncuestasFilter({
        ...encuestas_filter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };

  const goToEncuesta = (encuesta) => {
    navigate(`/encuesta/${encuesta.id}/configuracion`);
  };

  const onCheckAll = () => {
    let checkedData = JSON.parse(JSON.stringify(encuestas));
    if (encuestas_checked.length > 0) {
      checkedData = [];
    }
    dispatch(encuestaActions.setEncuestasChecked(checkedData));
  };

  const onCheckEncuesta = (item) => (event) => {
    dispatch(
      encuestaActions.checkEncuesta({
        encuesta: item,
        checked: event.target.checked,
      })
    );
  };

  const actionOptions = [
    {
      value: "cerrar",
      label: "Cerrar encuestas",
    },
    {
      value: "abrir",
      label: "Reabrir encuestas",
    },
    {
      value: "eliminar",
      label: "Eliminar",
    },
  ];

  const onAction = (v) => {
    if (v.value === "cerrar") {
      const deseaCerrar = window.confirm(
        "¿Está seguro de cerrar las encuestas seleccionadas?"
      );
      if (!!deseaCerrar) {
        const ids = encuestas_checked.map((encuesta) => encuesta.id);
        dispatch(
          encuestaActions.bulkUpdateEstados({ ids: ids, estado: false })
        );
      }
      return;
    } else if (v.value === "abrir") {
      const deseaAbrir = window.confirm(
        "¿Está seguro de abrir las encuestas seleccionadas?"
      );
      if (!!deseaAbrir) {
        const ids = encuestas_checked.map((encuesta) => encuesta.id);
        dispatch(encuestaActions.bulkUpdateEstados({ ids: ids, estado: true }));
      }
      return;
    } else if (v.value === "eliminar") {
      const deseaEliminar = window.confirm(
        "¿Está seguro de eliminar las encuestas seleccionadas?"
      );
      if (!!deseaEliminar) {
        const ids = encuestas_checked.map((encuesta) => encuesta.id);
        dispatch(encuestaActions.bulkDeleteEncuestas(ids));
      }
      return;
    }
  };

  return (
    <div className="w-full my-6">
      <div className="w-full flex items-center mb-4 gap-4 justify-between px-6">
        {/* @ts-ignore */}
        <SearchInput
          value={nombre}
          onChange={setNombre}
          className=" w-full md:w-1/2"
        />
        {/* @ts-ignore */}
        <MostrarPaginas
          containerClass={"w-1/2x"}
          defaultOption={10}
          onChangePageSize={(e) => setPageSize(e.target.value)}
        />
        {/* @ts-ignore */}
        <ReactSelect
          className="w-auto h-10 inline-grid text-sm"
          value={{
            value: "",
            label: `(${encuestas_checked.length}) seleccionados`,
          }}
          options={actionOptions}
          onChange={onAction}
        />
        {/* @ts-ignore */}
        <PrimaryButton onClick={() => navigate("/encuesta/configuracion")}>
          Crear encuesta
        </PrimaryButton>
      </div>
      <div className="px-4">
        {/* @ts-ignore */}
        <DataTable
          containerClass={"my-8"}
          data={encuestas}
          loading={encuestas_loading}
          config={datatableConfig}
          // Pagination
          totalRecords={encuestas_count}
          onPaginate={onPaginate}
          outerPageSize={pageSize}
          outerPage={page}
          outerSetPage={setPage}
          //
          checkable={true}
          onCheckAll={onCheckAll}
          checkedItems={encuestas_checked}
          onItemCheck={onCheckEncuesta}
        />
      </div>
    </div>
  );
}

export default EncuestasPage;
