import axios from "axios";

export const ESTADOS_EVALUACION = {
  EN_CURSO: 1,
  FINALIZADO: 2,
};

export const ETAPAS = {
  EVALUACION: 1,
  CALIBRACION: 2,
  DEVOLUCION: 3,
  COMENTARIO: 4,
};

export const ESTADOS_FORMULARIO = {
  PENDIENTE: 0,
  ENVIADO: 1,
  EVALUADO: 2,
  CALIBRADO: 3,
  DEVUELTO: 4,
  COMENTADO: 5,
  CONFIRMADO: 6,
};

// export const ETAPA_POR_ESTADOS = {
//   [ETAPAS.EVALUACION]: [ESTADOS_FORMULARIO.EVALUADO],
//   [ETAPAS.CALIBRACION]: [ESTADOS_FORMULARIO.CALIBRADO],
//   [ETAPAS.DEVOLUCION]: [ESTADOS_FORMULARIO.DEVUELTO],
//   [ETAPAS.COMENTARIO]: [ESTADOS_FORMULARIO.COMENTADO],
// };

export const ETAPA_POR_ESTADOS = {
  [ETAPAS.EVALUACION]: ESTADOS_FORMULARIO.EVALUADO,
  [ETAPAS.CALIBRACION]: ESTADOS_FORMULARIO.CALIBRADO,
  [ETAPAS.DEVOLUCION]: ESTADOS_FORMULARIO.DEVUELTO,
  [ETAPAS.COMENTARIO]: ESTADOS_FORMULARIO.COMENTADO,
};

export const modificarCalificacionHelper = (
  competenciasKey,
  competencias,
  competenciaId,
  conductaId,
  calificacionId,
  campo, // calificacion o comentarios
  valor
) => {
  let competenciasCopy = JSON.parse(JSON.stringify(competencias));
  let competenciaTemp;
  let conductaTemp;
  let competenciaIdx;
  let conductaIdx;
  let calificacionIdx;
  //
  competenciaTemp = competencias.find(
    (competencia) => competencia.id === competenciaId
  );
  competenciaIdx = competencias.findIndex(
    (competencia) => competencia.id === competenciaId
  );
  conductaTemp = competenciaTemp.conductas.find(
    (conducta) => conducta.id === conductaId
  );
  conductaIdx = competenciaTemp.conductas.findIndex(
    (conducta) => conducta.id === conductaId
  );
  calificacionIdx = conductaTemp.calificaciones.findIndex(
    (calificacion) => calificacion.id === calificacionId
  );
  // CONTROL DE NO APLICA
  const etapaValue =
    competenciasCopy[competenciaIdx].conductas[conductaIdx].calificaciones[
      calificacionIdx
    ].etapa.value;
  const calificacionesEtapa = competenciasCopy[competenciaIdx].conductas.map(
    (conducta, cidx) =>
      conducta.calificaciones.filter(
        (calif, caidx) => calif.etapa.value === etapaValue
      )
  );
  let sumaCalif = 0;
  const calificacionSeleccionada =
    competenciasCopy[competenciaIdx].conductas[conductaIdx].calificaciones[
      calificacionIdx
    ];
  calificacionesEtapa.forEach((calif, caidx) => {
    if (calificacionSeleccionada.id === calif[0].id) {
      return;
    }
    sumaCalif += calif[0].calificacion;
  });
  sumaCalif += valor;
  // sumaPesos se usa para saber si las competencias tienen pesos cargados o no. Solo si tienen no se podran poner todas las conductas como No Aplica.
  // let sumaPesos = competenciasCopy.reduce(
  //   (accumulator, cg) => accumulator + cg.peso,
  //   0
  // );
  // const avg = sumaCalif / calificacionesEtapa.length;
  // if (avg === -1 && sumaPesos !== 0) {
  //   return {
  //     status: "error",
  //     error: "Debe haber al menos un indicador que aplique",
  //   };
  // }

  competenciasCopy[competenciaIdx].conductas[conductaIdx].calificaciones[
    calificacionIdx
  ][campo] = valor;
  //
  return { [competenciasKey]: competenciasCopy };
};
export const modificarCalificacionKPIHelper = (
  competenciasKey,
  competencias,
  competenciaId,
  campo,
  valor,
  valorSecundario // para calificacion/cumplimiento
) => {
  let competenciasCopy = JSON.parse(JSON.stringify(competencias));
  let competenciaIdx;
  //
  competenciaIdx = competencias.findIndex(
    (competencia) => competencia.id === competenciaId
  );
  //
  const kpiCompetencia = competenciasCopy[competenciaIdx];
  const etapaCompetencia = kpiCompetencia.etapa;
  const kpisEtapa = competenciasCopy.filter(
    (ckpi, ckidx) =>
      ckpi.etapa === etapaCompetencia && ckpi.id !== kpiCompetencia.id
  );
  //
  let sumaCalif = 0;
  kpisEtapa.forEach((kpi, kidx) => (sumaCalif += kpi.calificacion));
  if (valor !== "") {
    sumaCalif += valor;
  }
  const avg = sumaCalif / (kpisEtapa.length + 1);
  if (avg === -1) {
    return {
      status: "error",
      error: "Debe haber al menos un KPI que aplique",
    };
  }
  competenciasCopy[competenciaIdx][campo] = valor !== "" ? valor : null;
  //
  if (campo === "calificacion" && valor === -1) {
    competenciasCopy[competenciaIdx].alcance = null;
  }
  if (valorSecundario !== null && valorSecundario !== undefined) {
    competenciasCopy[competenciaIdx].calificacion = valorSecundario;
  }
  //
  return { [competenciasKey]: competenciasCopy };
};

const obtenerCalificaciones = (competencias, etapa = 1) => {
  /* Función auxiliar para recorrer competencias */
  let calificaciones = [];
  //
  competencias.forEach((competencia, cIdx) =>
    competencia.conductas.forEach((conducta, conIdx) => {
      let calificacion = conducta.calificaciones.find(
        (calif, califIdx) => calif.etapa.value === etapa
      );
      calificaciones.push(calificacion);
    })
  );
  //
  return calificaciones;
};
const obtenerCalificacionesKpis = (competencias, etapa = 1) => {
  let calificaciones = [];
  //
  calificaciones = competencias.filter((c, cIdx) => c.etapa === etapa);
  //
  calificaciones = calificaciones.map((competencia, cIdx) => ({
    id: competencia.id,
    calificacion: competencia.calificacion,
    alcance: competencia.alcance,
    comentarios: competencia.comentarios,
  }));
  //
  return calificaciones;
};

export const obtenerCalificacionesFormulario = (formulario, etapa = 1) => {
  /* Parsea calificaciones de un formulario para enviar al servidor. */
  const {
    competencias_generales = [],
    competencias_areas = [],
    competencias_cargos = [],
    calificaciones_kpis = [],
    calificaciones_objetivos_cargo = [],
  } = formulario;
  let calificaciones = {};
  //
  if (competencias_generales.length > 0) {
    calificaciones["calificaciones_generales"] = obtenerCalificaciones(
      competencias_generales,
      etapa
    );
  }
  if (competencias_areas.length > 0) {
    calificaciones["calificaciones_areas"] = obtenerCalificaciones(
      competencias_areas,
      etapa
    );
  }
  if (competencias_cargos.length > 0) {
    calificaciones["calificaciones_cargos"] = obtenerCalificaciones(
      competencias_cargos,
      etapa
    );
  }
  if (calificaciones_kpis.length > 0) {
    calificaciones["calificaciones_kpis"] = obtenerCalificacionesKpis(
      calificaciones_kpis,
      etapa
    );
  }
  if (calificaciones_objetivos_cargo.length > 0) {
    calificaciones["calificaciones_objetivos_cargo"] =
      obtenerCalificacionesKpis(calificaciones_objetivos_cargo, etapa);
  }
  //
  return calificaciones;
};

const calcularPorcentajeCompetencia = (competencias = [], etapa = 1) => {
  let cantidadCompetenciasNoAplican = 0; // Cantidad de competencias que no aplican en la etapa
  let sumaPesoNoAplica = 0; // Se iran sumando los pesos de las competencias no aplicables (que todas sus conductas están en No aplica en la etapa recibida)

  // Recorre cada competencia para identificar las que tienen todas las conductas como "No Aplica"
  competencias.forEach((cg) => {
    const todasNoAplica = cg.conductas.every((conducta) =>
      conducta.calificaciones.some(
        (calif) => calif.etapa.value === etapa && calif.calificacion === -1
      )
    );
    if (todasNoAplica) {
      cantidadCompetenciasNoAplican += 1;
      sumaPesoNoAplica += cg.peso;
    }
  });

  const cantidadCompetenciasValidas =
    competencias.length - cantidadCompetenciasNoAplican;
  const pesoADistribuir = cantidadCompetenciasValidas
    ? sumaPesoNoAplica / cantidadCompetenciasValidas
    : 0; // Cuanto peso más le corresponderá a cada competencia que sí aplica

  // Calcula el puntaje total de las competencias
  const sumaCompetenciasGenerales = competencias.reduce((suma, cg) => {
    // Determina el peso de la competencia actual
    const pesoCompetencia = cg.peso
      ? cg.peso + pesoADistribuir
      : cantidadCompetenciasValidas
      ? 100 / cantidadCompetenciasValidas
      : 0;

    let sumaCalificaciones = 0;
    let cantidadNoAplica = 0;

    // Recorre las conductas de la competencia para sumar las calificaciones
    cg.conductas.forEach((conducta) => {
      const calificacion = conducta.calificaciones.find(
        (calif) => calif.etapa.value === etapa
      );
      if (calificacion) {
        if (calificacion.calificacion !== -1) {
          sumaCalificaciones += calificacion.calificacion;
        } else {
          cantidadNoAplica += 1;
        }
      }
    });

    const cantidadCalificaciones = cg.conductas.length - cantidadNoAplica || 1;
    const promedio = sumaCalificaciones / cantidadCalificaciones;
    const puntaje = (promedio * pesoCompetencia) / 100;

    return cg.conductas.length !== cantidadNoAplica ? suma + puntaje : suma;
  }, 0);

  // Se retorna el puntaje total de las competencias y la cantidad de competencias validadas
  const result = {
    puntaje: sumaCompetenciasGenerales,
    validos: cantidadCompetenciasValidas,
  };
  return result;
};

const calcularPorcentajeCompetenciaArea = (competencias = [], etapa = 1) => {
  /**
   * Agrupa las competencias por competencia_area y calcula el porcentaje
   * de logro de cada grupo de competencias.
   */

  const competenciasAgrupadas = competencias.reduce((acc, competencia) => {
    const area = competencia.competencia_area;
    if (!acc[area]) {
      acc[area] = [];
    }
    acc[area].push(competencia);
    return acc;
  }, {});

  let cantidadGruposValidos = 0;
  const sumaCompetenciasAreas = Object.values(competenciasAgrupadas).reduce(
    (acc, competencias) => {
      const { puntaje, validos } = calcularPorcentajeCompetencia(
        competencias,
        etapa
      );
      if (validos > 0) {
        cantidadGruposValidos += 1;
      }
      return acc + puntaje;
    },
    0
  );
  return sumaCompetenciasAreas / (cantidadGruposValidos || 1);
};

const calcularPorcentajeCompetenciaCargo = (competencias = [], etapa = 1) => {
  /**
   * Agrupa las competencias por competencia_cargo y calcula el porcentaje
   * de logro de cada grupo de competencias.
   */

  const competenciasAgrupadas = competencias.reduce((acc, competencia) => {
    const cargo = competencia.competencia_cargo;
    if (!acc[cargo]) {
      acc[cargo] = [];
    }
    acc[cargo].push(competencia);
    return acc;
  }, {});

  let cantidadGruposValidos = 0;
  const sumaCompetenciasCargos = Object.values(competenciasAgrupadas).reduce(
    (acc, competencias) => {
      const { puntaje, validos } = calcularPorcentajeCompetencia(
        competencias,
        etapa
      );
      if (validos > 0) {
        cantidadGruposValidos += 1;
      }
      return acc + puntaje;
    },
    0
  );
  return sumaCompetenciasCargos / (cantidadGruposValidos || 1);
};

const calcularPorcentajeKPI = (kpis = [], etapa = 1) => {
  const kpisEtapa = kpis.filter((kpi, kpii) => kpi.etapa === etapa);
  const cantidadKpisEtapa = kpisEtapa.length;
  if (cantidadKpisEtapa < 1) {
    return 0;
  }
  //
  let sumaCalificaciones = 0;
  let cantidadNoAplica = kpisEtapa.filter(
    (ckpi, idx) => ckpi.calificacion === -1
  );
  let cantidadKpisAux = cantidadKpisEtapa - cantidadNoAplica.length;
  let sumaPesoNoAplica = 0;
  cantidadNoAplica.forEach((na, naidx) => (sumaPesoNoAplica += na.kpi.peso));
  const pesoADistribuir = !!cantidadKpisAux
    ? sumaPesoNoAplica / cantidadKpisAux
    : 0;
  //
  kpisEtapa.forEach((ckpi, ckpiIdx) => {
    const { calificacion, kpi } = ckpi;
    const { meta, peso } = kpi;
    //
    const pesoAux = !!peso
      ? peso + pesoADistribuir
      : cantidadKpisAux > 0
      ? Number(100 / cantidadKpisAux)
      : 0;
    if (calificacion !== -1) {
      let calificacionAux = (calificacion * Number(pesoAux)) / 100;
      sumaCalificaciones += calificacionAux;
    }
  });
  //
  return Number(sumaCalificaciones).toFixed(2);
};
const calcularPorcentajeObjetivoCargo = (objetivosCargo = [], etapa = 1) => {
  const objetivosCargoEtapa = objetivosCargo.filter(
    (objetivoCargo, objetivoCargo_) => objetivoCargo.etapa === etapa
  );
  const cantidadObjetivosCargoEtapa = objetivosCargoEtapa.length;
  if (cantidadObjetivosCargoEtapa < 1) {
    return 0;
  }
  //
  let sumaCalificaciones = 0;
  let cantidadNoAplica = objetivosCargoEtapa.filter(
    (cObjetivoCargo, idx) => cObjetivoCargo.calificacion === -1
  );
  let cantidadObjetivosCargoAux =
    cantidadObjetivosCargoEtapa - cantidadNoAplica.length;
  let sumaPesoNoAplica = 0;
  cantidadNoAplica.forEach(
    (na, naidx) => (sumaPesoNoAplica += na.objetivo_cargo.peso)
  );
  const pesoADistribuir = !!cantidadObjetivosCargoAux
    ? sumaPesoNoAplica / cantidadObjetivosCargoAux
    : 0;
  //
  objetivosCargoEtapa.forEach((cObjetivoCargo, cObjetivoCargoIdx) => {
    const { calificacion, objetivo_cargo } = cObjetivoCargo;
    const { meta, peso } = objetivo_cargo;
    //
    const pesoAux = !!peso
      ? peso + pesoADistribuir
      : cantidadObjetivosCargoAux > 0
      ? Number(100 / cantidadObjetivosCargoAux)
      : 0;
    if (calificacion !== -1) {
      let calificacionAux = (calificacion * Number(pesoAux)) / 100;
      sumaCalificaciones += calificacionAux;
    }
  });
  //
  return Number(sumaCalificaciones).toFixed(2);
};
const calcularPuntaje = (porcentajes = {}, configuracion = {}) => {
  const {
    peso_generales = 0,
    peso_areas = 0,
    peso_cargos = 0,
    peso_kpis = 0,
    peso_objetivos_cargo = 0,
  } = configuracion || {};
  const {
    generales = 0,
    areas = 0,
    cargos = 0,
    kpis = 0,
    objetivos_cargo = 0,
  } = porcentajes;
  let puntaje = 0;
  if (peso_generales) {
    puntaje += Number(Number((generales * peso_generales) / 100).toFixed(2));
  }
  if (peso_areas) {
    puntaje += Number(Number((areas * peso_areas) / 100).toFixed(2));
  }
  if (peso_cargos) {
    puntaje += Number(Number((cargos * peso_cargos) / 100).toFixed(2));
  }
  if (peso_kpis) {
    puntaje += Number(Number((kpis * peso_kpis) / 100).toFixed(2));
  }
  if (objetivos_cargo) {
    puntaje += Number(
      Number((objetivos_cargo * peso_objetivos_cargo) / 100).toFixed(2)
    );
  }
  return puntaje;
};

export const calcularPuntajeFormulario = (formulario, etapa = 1) => {
  let {
    competencias_generales,
    competencias_areas,
    competencias_cargos,
    calificaciones_kpis,
    calificaciones_objetivos_cargo,
    configuracion = {},
  } = formulario;
  let porcentajes = {};
  //
  const porcentajeGenerales = calcularPorcentajeCompetencia(
    competencias_generales,
    etapa
  ).puntaje; // Se retorna solo el puntaje
  const porcentajeAreas = calcularPorcentajeCompetenciaArea(
    competencias_areas,
    etapa
  );
  const porcentajeCargos = calcularPorcentajeCompetenciaCargo(
    competencias_cargos,
    etapa
  );
  const porcentajeKpis = calcularPorcentajeKPI(calificaciones_kpis, etapa);
  const porcentajeObjetivosCargo = calcularPorcentajeObjetivoCargo(
    calificaciones_objetivos_cargo,
    etapa
  );
  //
  porcentajes.generales = porcentajeGenerales;
  porcentajes.areas = porcentajeAreas;
  porcentajes.cargos = porcentajeCargos;
  /* porcentaje total de kpis y objetivos debe ser solo hasta 100 para que no sea mayor 
  al porcentaje que se le puso al configurar el formulario en la evaluación */
  porcentajes.kpis = porcentajeKpis > 100 ? 100 : porcentajeKpis;
  porcentajes.objetivos_cargo =
    porcentajeObjetivosCargo > 100 ? 100 : porcentajeObjetivosCargo;
  //
  return calcularPuntaje(porcentajes, configuracion);
};

export const obtenerCompetenciasCalificadasCopia = (
  formulario,
  etapaOrigen = 1,
  etapaDestino = 2
) => {
  const {
    competencias_generales,
    competencias_areas,
    competencias_cargos,
    calificaciones_kpis,
    calificaciones_objetivos_cargo,
  } = formulario;
  let cgc = JSON.parse(JSON.stringify(competencias_generales));
  let cac = JSON.parse(JSON.stringify(competencias_areas));
  let ccc = JSON.parse(JSON.stringify(competencias_cargos));
  let ckc = JSON.parse(JSON.stringify(calificaciones_kpis));
  let coc = JSON.parse(JSON.stringify(calificaciones_objetivos_cargo));
  //
  cgc.forEach((cg, cgi) =>
    cg.conductas.forEach((conducta, condIdx) =>
      conducta.calificaciones.forEach((calif, calIdx) => {
        //let califDestino = calif;
        if (calif.etapa.value === etapaDestino) {
          let califOrigen = conducta.calificaciones.find(
            (cali) => cali.etapa.value === etapaOrigen
          );
          cgc[cgi].conductas[condIdx].calificaciones[calIdx].calificacion =
            califOrigen.calificacion;
          cgc[cgi].conductas[condIdx].calificaciones[calIdx].comentarios =
            califOrigen.comentarios;
          // califDestino.calificacion = califOrigen.calificacion;
          // califDestino.comentarios = califOrigen.comentarios;
        }
        // return califDestino;
      })
    )
  );
  cac.forEach((ca, cai) =>
    ca.conductas.forEach((conducta, condIdx) =>
      conducta.calificaciones.forEach((calif, calIdx) => {
        //let califDestino = calif;
        if (calif.etapa.value === etapaDestino) {
          let califOrigen = conducta.calificaciones.find(
            (cali) => cali.etapa.value === etapaOrigen
          );
          cac[cai].conductas[condIdx].calificaciones[calIdx].calificacion =
            califOrigen.calificacion;
          cac[cai].conductas[condIdx].calificaciones[calIdx].comentarios =
            califOrigen.comentarios;
          // califDestino.calificacion = califOrigen.calificacion;
          // califDestino.comentarios = califOrigen.comentarios;
        }
        // return califDestino;
      })
    )
  );
  ccc.forEach((cc, cci) =>
    cc.conductas.forEach((conducta, condIdx) =>
      conducta.calificaciones.forEach((calif, calIdx) => {
        //let califDestino = calif;
        if (calif.etapa.value === etapaDestino) {
          let califOrigen = conducta.calificaciones.find(
            (cali) => cali.etapa.value === etapaOrigen
          );
          ccc[cci].conductas[condIdx].calificaciones[calIdx].calificacion =
            califOrigen.calificacion;
          ccc[cci].conductas[condIdx].calificaciones[calIdx].comentarios =
            califOrigen.comentarios;
          // califDestino.calificacion = califOrigen.calificacion;
          // califDestino.comentarios = califOrigen.comentarios;
        }
        // return califDestino;
      })
    )
  );
  ckc.forEach((ck, cki) => {
    if (ck.etapa === etapaDestino) {
      let califDestino = ckc.find(
        (ckf, ckfi) => ckf.etapa === etapaOrigen && ckf.kpi.id === ck.kpi.id
      );
      ckc[cki].calificacion = califDestino.calificacion;
      ckc[cki].alcance = califDestino.alcance;
      ckc[cki].comentarios = califDestino.comentarios;
    }
  });
  coc.forEach((co, coi) => {
    if (co.etapa === etapaDestino) {
      let califDestino = coc.find(
        (cof, cofi) =>
          cof.etapa === etapaOrigen &&
          cof.objetivo_cargo.id === co.objetivo_cargo.id
      );
      coc[coi].calificacion = califDestino.calificacion;
      coc[coi].alcance = califDestino.alcance;
      coc[coi].comentarios = califDestino.comentarios;
    }
  });
  //
  return {
    competencias_generales: cgc,
    competencias_areas: cac,
    competencias_cargos: ccc,
    calificaciones_kpis: ckc,
    calificaciones_objetivos_cargo: coc,
  };
};

export const getEtapaAnteriorOSiguiente = (
  evaluacion,
  etapaActual,
  buscar = "anterior",
  formulario
) => {
  /* Solo aplica para formularios de Jefe directo*/
  const {
    tiene_calibracion_superior,
    tiene_devolucion,
    tiene_comentarios_evaluado,
  } = evaluacion;
  let etapasDisponibles = [ETAPAS.EVALUACION];
  //
  if (formulario.relacion === "Jefe directo") {
    if (tiene_calibracion_superior) {
      if (!!formulario && formulario.calibrador) {
        etapasDisponibles.push(ETAPAS.CALIBRACION);
      }
    }
    if (tiene_devolucion) {
      etapasDisponibles.push(ETAPAS.DEVOLUCION);
    }
    if (tiene_comentarios_evaluado) {
      etapasDisponibles.push(ETAPAS.COMENTARIO);
    }
  }
  //
  const indexActual = etapasDisponibles.findIndex((e) => e === etapaActual);
  if (buscar === "anterior") {
    return etapasDisponibles[indexActual - 1] || 0;
  } else if (buscar === "siguiente") {
    return etapasDisponibles[indexActual + 1] || 0;
  }
  return 0;
};

export const getEstadoFormularioAnteriorOSiguiente = (
  evaluacion,
  estadoActual,
  buscar = "anterior",
  formulario
) => {
  const {
    tiene_calibracion_superior,
    tiene_devolucion,
    tiene_comentarios_evaluado,
  } = evaluacion;
  let estadosDisponibles = [
    ESTADOS_FORMULARIO.PENDIENTE,
    ESTADOS_FORMULARIO.ENVIADO,
    ESTADOS_FORMULARIO.EVALUADO,
  ];
  //
  if (tiene_calibracion_superior) {
    if (!!formulario && formulario.calibrador) {
      estadosDisponibles.push(ESTADOS_FORMULARIO.CALIBRADO);
    }
  }
  if (tiene_devolucion) {
    estadosDisponibles.push(ESTADOS_FORMULARIO.DEVUELTO);
  }
  if (tiene_comentarios_evaluado) {
    estadosDisponibles.push(ESTADOS_FORMULARIO.COMENTADO);
  }
  estadosDisponibles.push(ESTADOS_FORMULARIO.CONFIRMADO);
  //
  const indexActual = estadosDisponibles.findIndex((e) => e === estadoActual);
  if (buscar === "anterior") {
    return estadosDisponibles[indexActual - 1] || -1;
  } else if (buscar === "siguiente") {
    return estadosDisponibles[indexActual + 1] || -1;
  }
  return -1;
};

export const getPuntuacionesCustom = (evaluacion) => {
  let puntuacionesCustom = [];
  const { puntuaciones_observacion = [] } = evaluacion;
  if (puntuaciones_observacion.length > 0) {
    puntuacionesCustom = puntuaciones_observacion.map((po, poi) => ({
      label: po.nombre,
      value: po.porcentaje,
    }));
    puntuacionesCustom.push({ label: "No aplica", value: -1 });
  }
  return puntuacionesCustom;
};
//
export function DescargarInforme(evaluacionId, token = "", onComplete = null) {
  const URL_BASE =
    process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";
  const requestURL =
    URL_BASE + `/evaluacion/${evaluacionId}/descargar-listado-colaboradores/`;
  const headers = {
    "Content-Type": "blob",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  //
  axios({
    url: requestURL,
    method: "GET",
    headers: headers,
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `listado-evaluaciones-${evaluacionId}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      //
      if (!!onComplete) {
        onComplete();
      }
    })
    .catch((e) => {
      console.info("Error al descargar el archivo");
      if (!!onComplete) {
        onComplete();
      }
    });
}
