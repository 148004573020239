import React from "react";
import { PlusCircleFill, TrashFill } from "react-bootstrap-icons";

const PreguntaComponent = ({
  index,
  texto,
  descripcion,
  tipoRespuesta,
  opciones = [],
  handleAddPregunta = null,
  handleInputChange = null,
  handleDelete = null,
  handleAddOpcion = null,
  handleDeleteOpcion = null,
}) => {
  return (
    <>
      <button
        className="mb-4 text-sky-200 hover:text-sky-300"
        title="Agregar pregunta aquí"
        onClick={() => handleAddPregunta(index)}
      >
        <PlusCircleFill size={24} />
      </button>
      <div className="flex flex-col gap-4 border rounded w-full p-4">
        <div className="flex flex-col">
          <label className="font-bold">
            Pregunta {index} <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="texto"
            placeholder="Ingrese la pregunta"
            value={texto}
            onChange={handleInputChange(index)}
            className="border rounded border-zinc-200 px-2 py-3 w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="font-bold">Descripción</label>
          <textarea
            name="descripcion"
            placeholder="Escriba aquí..."
            value={descripcion}
            onChange={handleInputChange(index)}
            className="border rounded border-zinc-200 px-2 py-3 w-full"
          />
        </div>
        <div className="flex flex-col">
          <label className="font-bold">
            Tipo de respuesta <span className="text-red-500">*</span>
          </label>
          <select
            name="tipo_respuesta"
            className="border rounded border-zinc-200 px-2 py-3 w-1/2"
            value={tipoRespuesta}
            onChange={handleInputChange(index)}
          >
            <option value="TEXTO">Campo de texto</option>
            <option value="FECHA">Campo de fecha</option>
            <option value="MULTIPLE">Selección múltiple</option>
            <option value="SIMPLE">Selección simple</option>
          </select>
        </div>
        {(tipoRespuesta === "MULTIPLE" || tipoRespuesta === "SIMPLE") && (
          <div className="flex flex-col gap-2">
            <label className="font-bold">
              Defina las opciones de respuesta
            </label>
            {opciones.map((opcion, opcionIndex) => (
              <div key={opcionIndex} className="flex flex-row gap-2">
                <input
                  type="text"
                  name="opciones"
                  placeholder={`Opción ${opcionIndex + 1}`}
                  value={opcion.texto}
                  onChange={handleInputChange(index, opcionIndex)}
                  className="border rounded border-zinc-200 px-2 py-3 w-full"
                />
                <button onClick={() => handleDeleteOpcion(index, opcionIndex)}>
                  {/* @ts-ignore */}
                  <TrashFill size={24} />
                </button>
              </div>
            ))}
            <span
              className="text-sm text-sky-500 cursor-pointer mt-2"
              onClick={() => handleAddOpcion(index)}
            >
              Agregar opción
            </span>
          </div>
        )}
        <span
          className="text-sm text-red-500 cursor-pointer mt-2"
          onClick={() => handleDelete(index)}
        >
          Eliminar pregunta
        </span>
      </div>
    </>
  );
};

export default PreguntaComponent;
