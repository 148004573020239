import React, { useEffect, useState } from "react";
import { ArrowBarDown, ArrowBarUp, Search, X } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "src/modules/common/components/DataTable";
import { ErrorToast } from "src/modules/common/components/ErrorToast";
import FileInput from "src/modules/common/components/FileInput";
import LoadingSpinButton from "src/modules/common/components/LoadingSpinButton";
import Modal from "src/modules/common/components/Modal";
import { SuccessToast } from "src/modules/common/components/SuccessToast";
import {
  formatNumber,
  formatNumberOnTyping,
  getToken,
} from "src/utils/helpers";
import RemuneracionLayout from "../components/RemuneracionLayout";
import { remuneracionActions } from "../handlers/redux";
import { DescargarPlantillaRemuneracion } from "../handlers/utils";

const NumberInput = ({
  mask = "",
  placeholder = "",
  // className = "w-full flex flex-row items-center border rounded-md px-2",
  className = "w-full flex flex-row items-center border rounded-md px-2",
  ...props
}) => {
  return (
    <div className={className}>
      {!!mask && <div className="flex w-20 text-xs">{mask}</div>}
      <input
        type="text"
        className="outline-none p-2 w-full"
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

const TableFilters = ({ filterState, onChangePageSize }) => {
  const dispatch = useDispatch();
  const onSearch = (e) => {
    let searchText = e.target.value;
    //
    dispatch(
      remuneracionActions.setSalariosMercadoFilter({
        ...filterState,
        nombre: searchText,
      })
    );
  };
  const onChangeRangoDesde = (e) => {
    let value = e.target.value;
    dispatch(
      remuneracionActions.setSalariosMercadoFilter({
        ...filterState,
        salario_mercado_desde: value,
      })
    );
  };
  const onChangeRangoHasta = (e) => {
    let value = e.target.value;
    if (value.length < 6 && value.length > 0) {
      return;
    }
    dispatch(
      remuneracionActions.setSalariosMercadoFilter({
        ...filterState,
        salario_mercado_hasta: value,
      })
    );
  };
  //
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-2 mt-4">
        {/* Busqueda Texto */}
        <div className="col-span-1 w-full flex items-center bg-slate-100 rounded-md p-2">
          <input
            type="text"
            className="w-full bg-slate-100 outline-none"
            placeholder="Buscar por nombre o cargo"
            value={filterState.nombre}
            onChange={onSearch}
          />
          <Search />
        </div>
        {/* Mostrar */}
        <div className="col-span-1">
          <div className="flex justify-center items-center gap-2 w-full">
            <span className="font-bold">Mostrar</span>
            <select
              name="mostrar"
              id="mostrar"
              className="p-2 rounded-md"
              onChange={onChangePageSize}
            >
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100" selected>
                100
              </option>
            </select>
          </div>
        </div>
        {/* Rangos */}
        <div className="col-span-2 flex items-center gap-1">
          <div className="font-bold">Rango</div>
          <NumberInput
            mask={"Min: Gs."}
            placeholder="00.000.000"
            onChange={onChangeRangoDesde}
          />
          <span className="font-bold">-</span>
          <NumberInput
            mask={"Max: Gs."}
            placeholder="00.000.000"
            onChange={onChangeRangoHasta}
          />
        </div>
      </div>
    </>
  );
};

function RemuneracionSalariosMercado() {
  const dispatch = useDispatch();
  const salariosMercado = useSelector(
    (state) => state.remuneracion.salarios_mercado
  );
  const { results, count } = salariosMercado;
  console.log("results salario_mercado", results);
  const salariosMercadoFilter = useSelector(
    (state) => state.remuneracion.salarios_mercado_filter
  );
  const salariosMercadoLoading = useSelector(
    (state) => state.remuneracion.salarios_mercado_loading
  );
  //importar_loading
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedSalario, setSelectedSalario] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const setOrdering =
    (field) =>
    (order = "") => {
      let { ordering = "" } = salariosMercadoFilter;
      let orderingArray = ordering.split(",") || [];
      let emptyIndex = orderingArray.indexOf("");
      if (emptyIndex) {
        orderingArray.splice(emptyIndex, 1);
      }
      let fieldIndex = orderingArray.indexOf(field);
      fieldIndex =
        fieldIndex === -1 ? orderingArray.indexOf(`-${field}`) : fieldIndex;
      // si ya esta el campo, vemos si se modifica o elimina
      if (fieldIndex > 0) {
        orderingArray.splice(fieldIndex, 1);
        if (order) {
          field = order === "desc" ? `-${field}` : field;
          orderingArray[fieldIndex] = field;
        }
      } else {
        // si no está, lo agregamos
        field = order === "desc" ? `-${field}` : field;
        orderingArray.push(field);
        !!process.env.REACT_APP_DEBUG &&
          console.info("orderingArray 2", orderingArray);
      }
      ordering = orderingArray.join(",");
      //
      dispatch(
        remuneracionActions.setSalariosMercadoFilter({
          ...salariosMercadoFilter,
          ordering: ordering,
        })
      );
    };

  const datatableConfig = {
    headers: [
      {
        title: "Cargo",
        accessor: "nombre",
        contentClass: "font-bold",
        onOrder: setOrdering("nombre"),
      },
      {
        title: "Monto base",
        accessor: "monto_base",
        onOrder: setOrdering("monto_base"),
        contentRenderer: (item) => (
          <span>{`Gs.${formatNumber(item.monto_base ?? 0)}`}</span>
        ),
      },
      {
        title: "Monto máximo",
        accessor: "monto_maximo",
        onOrder: setOrdering("monto_maximo"),
        contentRenderer: (item) => (
          <span>{`Gs.${formatNumber(item.monto_maximo ?? 0)}`}</span>
        ),
      },
      {
        title: "Promedio Mercado",
        accessor: "promedio_montos",
        onOrder: setOrdering("promedio_montos"),
        contentRenderer: (item) => (
          <span>{`Gs.${formatNumber(item.promedio_montos ?? 0)}`}</span>
        ),
      },
    ],
    rowActions: [
      {
        label: "Editar",
        action: (item) => {
          console.log("item", item);
          setSelectedSalario(item);
          setShowEditModal(true);
        },
      },
    ],
  };
  const [showFilters, setShowFilters] = useState(true);

  const onHideFilters = () => {
    setShowFilters(!showFilters);
  };

  const onPaginate = (page, pageSize) => {
    dispatch(
      remuneracionActions.setSalariosMercadoFilter({
        ...salariosMercadoFilter,
        pagination: {
          page: page,
          pageSize: pageSize,
        },
      })
    );
  };
  const [PageSize, setPageSize] = useState(100);
  const onChangePageSize = (e) => {
    const value = e.target.value;
    setPageSize(value);
  };
  const onChangeSelectedSalario = (field) => (e) => {
    let value = e.target?.value;
    value = formatNumberOnTyping(value);
    let selectedSalarioCopy = { ...selectedSalario };
    selectedSalarioCopy[field] = value;
    setSelectedSalario(selectedSalarioCopy);
  };
  const submitSalario = () => {
    let selectedSalarioCopy = { ...selectedSalario };
    let { monto_base, monto_maximo, promedio_montos } = selectedSalarioCopy;
    //
    const montoBaseClean = (monto_base + "")
      .replaceAll(".", "")
      .replace(",", ".");
    const montoMaximoClean = (monto_maximo + "")
      .replaceAll(".", "")
      .replace(",", ".");
    const promedioMontosClean = (promedio_montos + "")
      .replaceAll(".", "")
      .replace(",", ".");
    //
    if (!montoBaseClean || isNaN(montoBaseClean)) {
      setFormErrors({
        ...formErrors,
        monto_base: "Debe ingresar un salario válido",
      });
      return;
    }
    if (!montoMaximoClean || isNaN(montoMaximoClean)) {
      setFormErrors({
        ...formErrors,
        monto_maximo: "Debe ingresar un salario válido",
      });
      return;
    }
    if (!promedioMontosClean || isNaN(promedioMontosClean)) {
      setFormErrors({
        ...formErrors,
        promedio_montos: "Debe ingresar un salario valido",
      });
      return;
    }
    selectedSalarioCopy.monto_base = montoBaseClean;
    selectedSalarioCopy.monto_maximo = montoMaximoClean;
    selectedSalarioCopy.promedio_montos = promedioMontosClean;
    setShowEditModal(false);
    dispatch(remuneracionActions.setSalarioMercadoEditar(selectedSalarioCopy));
    setFormErrors({});
  };
  //
  const [plantillaEjemploLoading, setPlantillaEjemploLoading] = useState(false);
  const [plantillaSalariosLoading, setPlantillaSalariosLoading] =
    useState(false);
  const token = getToken();
  const descargarPlantillaEjemplo = () => {
    const plantilla = "salarios-mercado-ejemplo";
    DescargarPlantillaRemuneracion({
      plantilla,
      token,
      setLoading: setPlantillaEjemploLoading,
    });
  };
  const descargarPlantillaSalarios = () => {
    const plantilla = "salarios-mercado";
    DescargarPlantillaRemuneracion({
      plantilla,
      token,
      setLoading: setPlantillaSalariosLoading,
    });
  };
  //
  const [showImportarModal, setShowImportarModal] = useState(false);
  const abrirImportarModal = () => {
    setShowImportarModal(true);
  };
  const importar_loading = useSelector(
    (state) => state.remuneracion.importar_loading
  );
  const fileToImportarResponse = useSelector(
    (state) => state.remuneracion.fileToImportarMercadoResponse
  );
  //
  const uploadFileImportar = (file) => {
    setErroresImportar([]);
    dispatch(remuneracionActions.setFileToUploadImportarMercado(file));
  };
  //
  useEffect(() => {
    dispatch(remuneracionActions.getSalariosMercado());
    dispatch(remuneracionActions.setFileToUploadImportarResponse({}));
    setErroresImportar([]);
  }, []);
  //
  const [erroresImportar, setErroresImportar] = useState([]);
  useEffect(() => {
    if (fileToImportarResponse) {
      console.log("useEffect fileToImportarResponse", fileToImportarResponse);
      if (fileToImportarResponse.success) {
        setShowImportarModal(false);
        SuccessToast({ message: "Salarios importados con éxito!" });
        dispatch(remuneracionActions.setFileToUploadImportarResponse({}));
      } else if (fileToImportarResponse?.error) {
        ErrorToast({ message: fileToImportarResponse.error });
      } else if (fileToImportarResponse.errores?.length > 0) {
        setErroresImportar(fileToImportarResponse.errores);
      }
    }
  }, [fileToImportarResponse]);

  return (
    <RemuneracionLayout>
      <div className="md:w-11/12">
        {/* IMPORTACION/EXPORTACION */}
        <div className="border-b pb-2 mt-4 flex justify-between items-center">
          <h2 className="font-semibold">Salarios del mercado</h2>
          <div className="flex gap-2 items-center">
            <div className="flex items-center gap-1 border border-black rounded-md px-3 py-1">
              <ArrowBarDown />
              {!plantillaEjemploLoading && (
                <button onClick={descargarPlantillaEjemplo} className="text-sm">
                  Descargar plantilla Excel
                </button>
              )}
              {!!plantillaEjemploLoading && (
                <div className="text-sm animate-pulse">Descargando...</div>
              )}
            </div>
            {/*  */}
            <div className="flex items-center gap-1 border border-black rounded-md px-3 py-1">
              <ArrowBarDown />
              {!plantillaEjemploLoading && (
                <button
                  onClick={descargarPlantillaSalarios}
                  className="text-sm"
                >
                  Exportar datos
                </button>
              )}
              {!!plantillaEjemploLoading && (
                <div className="text-sm animate-pulse">Descargando...</div>
              )}
            </div>
            {/*  */}
            <div className="rounded-md px-3 py-1 bg-black flex items-center gap-1">
              <ArrowBarUp color={"white"} />
              <button
                onClick={abrirImportarModal}
                className="text-white text-sm"
              >
                Importar datos
              </button>
            </div>
            <div className="flex items-center">
              <button
                className="font-bold text-sky-500"
                onClick={onHideFilters}
              >
                Ocultar filtros
              </button>
            </div>
          </div>
        </div>
        {/*  */}
        {!!showFilters && (
          <div
            className={`${
              !!showFilters ? "" : "h-0"
            } transition-all delay-150 duration-500 ease-in-out`}
          >
            <TableFilters
              onChangePageSize={onChangePageSize}
              filterState={salariosMercadoFilter}
            />
          </div>
        )}
        <DataTable
          containerClass={"my-8"}
          data={results}
          loading={salariosMercadoLoading}
          config={datatableConfig}
          totalRecords={count}
          onPaginate={onPaginate}
          outerPageSize={PageSize}
          checkable={false}
        />
        {/* MODAL FORM */}
        <Modal isOpen={showEditModal}>
          <div className="rounded-lg bg-white w-10/12 md:w-4/12 p-2 md:p-5 flex flex-col gap-5">
            <div className="modal-title">
              <div className="w-full flex justify-between items-center">
                <h2 className="font-semibold text-lg">Editar salario</h2>
                <button
                  onClick={() => {
                    setShowEditModal(false);
                    setFormErrors({});
                  }}
                >
                  <X size={24} />
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="flex flex-col gap-2 w-full justify-center">
                <div className="flex items-center mb-2">
                  <div
                    title={selectedSalario?.nombre || "Sin asignar"}
                    className="text-sm w-36 whitespace-nowrap text-ellipsis overflow-hidden"
                  >
                    Cargo: {selectedSalario?.nombre || "Sin asignar"}
                  </div>
                </div>
                <div className="form w-full flex flex-col gap-2">
                  <div className="form-control flex flex-col gap-2">
                    <label className="font-bold" htmlFor="salario_extra">
                      Monto base
                    </label>
                    <NumberInput
                      mask={"Gs."}
                      placeholder="00.000.000"
                      value={formatNumberOnTyping(selectedSalario?.monto_base)}
                      // value={selectedSalario?.salario_extra}
                      onChange={onChangeSelectedSalario("monto_base")}
                    />
                    {!!formErrors.monto_base && (
                      <div className="bg-red-500 rounded-md p-2 text-white text-sm">
                        {formErrors.monto_base}
                      </div>
                    )}
                  </div>
                  <div className="form-control flex flex-col gap-2">
                    <label className="font-bold" htmlFor="salario_extra">
                      Monto máximo<span className="text-red-500">*</span>
                    </label>
                    <NumberInput
                      mask={"Gs."}
                      placeholder="00.000.000"
                      value={formatNumberOnTyping(
                        selectedSalario?.monto_maximo
                      )}
                      // value={selectedSalario?.salario_fijo}
                      onChange={onChangeSelectedSalario("monto_maximo")}
                    />
                    {!!formErrors.monto_maximo && (
                      <div className="bg-red-500 rounded-md p-2 text-white text-sm">
                        {formErrors.monto_maximo}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-control flex flex-col gap-2">
                  <label className="font-bold" htmlFor="salario_extra">
                    Promedio del mercado
                  </label>
                  <NumberInput
                    mask={"Gs."}
                    placeholder="00.000.000"
                    value={formatNumberOnTyping(
                      selectedSalario?.promedio_montos
                    )}
                    onChange={onChangeSelectedSalario("promedio_montos")}
                  />
                  {!!formErrors.promedio_montos && (
                    <div className="bg-red-500 rounded-md p-2 text-white text-sm">
                      {formErrors.promedio_montos}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="flex justify-center w-full">
                <button
                  className="w-full bg-sky-500 text-white text-sm px-4 py-3 rounded-md"
                  onClick={submitSalario}
                >
                  Actualizar salario
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {/* MODAL IMPORTAR SALARIOS */}
        <Modal isOpen={showImportarModal}>
          <div className="bg-white rounded-md p-4 w-10/12 md:w-4/12">
            <div className="modal-title">
              <div className="flex items-center justify-between">
                <h2 className="font-semibold text-lg">Importar salarios</h2>
                <button onClick={() => setShowImportarModal(false)}>
                  <X size={24} />
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div>
                {!importar_loading && (
                  <div className="w-full flex items-center justify-center">
                    <FileInput
                      label={"Importador general"}
                      className={"mt-3 w-3/4"}
                      showButton={false}
                      onSubmit={uploadFileImportar}
                    />
                  </div>
                )}
                <div className="max-h-52 custom-scrollbar">
                  {erroresImportar.length > 0 && (
                    <div className="w-full bg-red-300 rounded-md p-2">
                      <h3 className="font-semibold">
                        Ocurrieron los siguientes errores:
                      </h3>
                      <ul className="">
                        {erroresImportar.map((error, eidx) => (
                          <li>{error}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                {!!importar_loading && (
                  <div className="py-8">
                    <LoadingSpinButton
                      className="w-full justify-center"
                      message="Importando, favor espere..."
                    ></LoadingSpinButton>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </RemuneracionLayout>
  );
}

export default RemuneracionSalariosMercado;
