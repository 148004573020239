import axios from "axios";
import { ObjectToFormData } from "src/utils/helpers";
const URL_BASE =
  process.env.REACT_APP_API_URL || "http://localhost:8000/api/admin";

export const colaboradorApi = (token) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  return {
    create: async (params) => {
      const headersForm = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      const formData = ObjectToFormData(params);
      // TODO: FIX
      let resp = {};
      axios({
        method: "POST",
        url: URL_BASE + "/empleado/create",
        data: formData,
        headers: headersForm,
      })
        .then(function (response) {
          // console.log("create api response", response);
          resp = response;
        })
        .catch(function (error) {
          // console.log("create api error", error);
          resp = error.response;
        });
      return { test: true };
    },
    update: async ({ id, ...params }) => {
      const headersForm = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
      const formData = ObjectToFormData(params);
      //
      axios({
        method: "PUT",
        url: URL_BASE + `/empleado/${id}/general/`,
        data: formData,
        headers: headersForm,
      })
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
          throw error;
        });
    },
    delete: async ({ id }) => {
      try {
        const data = await axios.delete(URL_BASE + `/empleado/${id}/delete`, {
          headers,
        });
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    get: async ({ id }) => {
      try {
        const colaborador = await axios.get(`${URL_BASE}/empleado/${id}/`, {
          headers,
        });
        return colaborador.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradores error", error);
        throw error;
      }
    },
    list: async ({ colaboradoresFilter }) => {
      const {
        nombre = "",
        areaNombre = "",
        sucursalNombre = "",
        departamentoNombre = "",
        jefeNombre = "",
        rol = "",
        ci = "",
        email = "",
        ordering = "",
        pagination = { page: 1, pageSize: 50 },
      } = colaboradoresFilter;
      const { page, pageSize } = pagination;
      !!process.env.REACT_APP_DEBUG &&
        console.info("colaboradoresFilter api", colaboradoresFilter);
      //
      let searchURL = `/empleado/?search=${nombre}`;
      if (areaNombre) searchURL = searchURL + `&area_nombre=${areaNombre}`;
      if (sucursalNombre)
        searchURL = searchURL + `&sucursal_nombre=${sucursalNombre}`;
      if (departamentoNombre)
        searchURL = searchURL + `&departamento_nombre=${departamentoNombre}`;
      if (jefeNombre) searchURL = searchURL + `&jefe_nombre=${jefeNombre}`;
      if (rol) searchURL = searchURL + `&usuario=${rol}`;
      if (email) searchURL = searchURL + `&email=${email}`;
      if (ci) searchURL = searchURL + `&ci=${ci}`;
      // i.e `?ordering=area__nombre,-sucursal__nombre`
      if (ordering) searchURL = searchURL + `&ordering=${ordering}`;
      if (pagination)
        searchURL = searchURL + `&page=${page}&page_size=${pageSize}`;
      //
      try {
        const colaboradores = await axios.get(URL_BASE + searchURL, {
          headers,
        });
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradores error", error);
        throw error;
      }
    },
    colaboradoresLibres: async ({ id }) => {
      try {
        const colaboradores = await axios.get(
          `${URL_BASE}/empleado/${id}/colaboradores-libres/`,
          {
            headers,
          }
        );
        return colaboradores.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradores error", error);
        throw error;
      }
    },
    // Laboral
    getLaboral: async ({ id }) => {
      try {
        const colaborador = await axios.get(
          `${URL_BASE}/empleado/${id}/laboral/`,
          {
            headers,
          }
        );
        return colaborador.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradores error", error);
        throw error;
      }
    },
    updateLaboral: async ({ id, ...params }) => {
      try {
        const data = await axios.put(
          URL_BASE + `/empleado/${id}/laboral/edit/`,
          { ...params },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    // Acceso
    getAcceso: async ({ id }) => {
      try {
        const colaborador = await axios.get(
          `${URL_BASE}/empleado/${id}/acceso/`,
          {
            headers,
          }
        );
        return colaborador.data?.usuario || {};
      } catch (error) {
        !!process.env.REACT_APP_DEBUG &&
          console.log("colaboradores error", error);
        throw error;
      }
    },
    updateAcceso: async ({ id, ...params }) => {
      !!process.env.REACT_APP_DEBUG &&
        console.info("updateAcceso api", { id, ...params });
      try {
        const data = await axios.put(
          URL_BASE + `/usuario/${id}/edit`,
          { ...params },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    invitacionAcceso: async ({ id, ...params }) => {
      const { forzarBienvenida = false, retornarLink = false } = params;
      try {
        const data = await axios.post(
          URL_BASE + `/empleado/${id}/enviar-invitacion/`,
          {
            forzar_bienvenida: forzarBienvenida,
            retornar_link: retornarLink,
            ...params,
          },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    roles: async () => {
      try {
        const roles = await axios.get(`${URL_BASE}/usuario/grupo/`, {
          headers,
        });
        return roles.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("roles error", error);
        throw error;
      }
    },
    // ACCIONES MASIVAS
    asignarSucursal: async ({ colaboradores, sucursal }) => {
      // Asignar varios Colaboradores por id a un Sucursal
      !!process.env.REACT_APP_DEBUG && console.info("asignarSucursales api");
      try {
        const data = await axios.post(
          URL_BASE + `/empleado/asignar-sucursales/`,
          { colaboradores: colaboradores, sucursal: sucursal },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    asignarArea: async ({ colaboradores, area }) => {
      // Asignar varios Colaboradores por id a un Area
      !!process.env.REACT_APP_DEBUG && console.info("asignarAreas api");
      try {
        const data = await axios.post(
          URL_BASE + `/empleado/asignar-areas/`,
          { colaboradores: colaboradores, area: area },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    asignarDepartamento: async ({ colaboradores, departamento }) => {
      // Asignar varios Colaboradores por id a un Departamento
      !!process.env.REACT_APP_DEBUG && console.info("asignarDepartamentos api");
      try {
        const data = await axios.post(
          URL_BASE + `/empleado/asignar-departamentos/`,
          { colaboradores: colaboradores, departamento: departamento },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    asignarCargo: async ({ colaboradores, cargo }) => {
      // Asignar varios Colaboradores por id a un Cargo
      !!process.env.REACT_APP_DEBUG && console.info("asignarCargos api");
      try {
        const data = await axios.post(
          URL_BASE + `/empleado/asignar-cargos/`,
          { colaboradores: colaboradores, cargo: cargo },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    asignarJefe: async ({ colaboradores, jefe }) => {
      // Asignar varios Colaboradores por id a un Jefe_directo
      !!process.env.REACT_APP_DEBUG && console.info("asignarJefes api");
      try {
        const data = await axios.post(
          URL_BASE + `/empleado/asignar-jefes/`,
          { colaboradores: colaboradores, jefe_directo: jefe },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    asignarRol: async ({ colaboradores, rol }) => {
      // Asignar varios Colaboradores por id a un Jefe_directo
      !!process.env.REACT_APP_DEBUG && console.info("asignarRol api");
      try {
        const data = await axios.post(
          URL_BASE + `/empleado/asignar-rol/`,
          { colaboradores: colaboradores, rol: rol },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    deleteColaboradores: async ({ colaboradores }) => {
      try {
        const data = await axios.post(
          URL_BASE + `/empleado/delete`,
          { colaboradores: colaboradores },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    enviarInvitaciones: async ({ colaboradores, forzarBienvenida = false }) => {
      // Enviar varias invitaciones
      !!process.env.REACT_APP_DEBUG && console.info("enviarInvitaciones api");
      try {
        const data = await axios.post(
          URL_BASE + `/empleado/enviar-invitaciones/`,
          { colaboradores: colaboradores, forzar_bienvenida: forzarBienvenida },
          { headers }
        );
        return data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log(JSON.stringify(error));
        throw error;
      }
    },
    // IMPORTACION
    importarVerificar: async ({ fileToUpload }) => {
      try {
        const payload = {
          file: fileToUpload,
        };
        const headersForm = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        !!process.env.REACT_APP_DEBUG && console.info("cargos importar api");
        const response = await axios.post(
          URL_BASE + `/empleado/importar-nomina-verificar/`,
          { file: fileToUpload },
          { headers: headersForm }
        );
        !!process.env.REACT_APP_DEBUG && console.info("response", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("nomina error", error);
        throw error;
      }
    },
    importar: async (data) => {
      try {
        !!process.env.REACT_APP_DEBUG &&
          console.info("cargos importar api", data);
        const response = await axios.post(
          URL_BASE + `/empleado/importar-nomina/`,
          { colaboradores: data },
          { headers }
        );
        !!process.env.REACT_APP_DEBUG && console.info("response", response);
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("nomina error", error);
        throw error;
      }
    },
    importarGeneralVerificar: async ({ fileToUpload }) => {
      try {
        const payload = {
          file: fileToUpload,
        };
        const headersForm = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.post(
          URL_BASE + `/empleado/importar-general-verificar/`,
          { file: fileToUpload },
          { headers: headersForm }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("nomina error", error);
        throw error;
      }
    },
    importarGeneral: async (data) => {
      try {
        const response = await axios.post(
          URL_BASE + `/empleado/importar-general/`,
          { ...data },
          { headers }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("nomina error", error);
        throw error;
      }
    },
    importarGeneralFullVerificar: async ({ fileToUpload }) => {
      try {
        const payload = {
          file: fileToUpload,
        };
        const headersForm = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        const response = await axios.post(
          URL_BASE + `/empleado/importar-general-full-verificar/`,
          { file: fileToUpload },
          { headers: headersForm }
        );
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("nomina error", error);
        throw error;
      }
    },
    importarGeneralFull: async (data) => {
      try {
        const response = await axios.post(
          URL_BASE + `/empleado/importar-general-full/`,
          { ...data },
          { headers }
        );
        //
        return response.data;
      } catch (error) {
        !!process.env.REACT_APP_DEBUG && console.log("nomina error", error);
        throw error;
      }
    },
    tiposContrataciones: async () => {
      try {
        const response = await axios.get(
          URL_BASE + "/empleado/tipos-contrataciones/",
          { headers }
        );
        return response.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    listEmpleadosSeleccionables: async (params) => {
      const {
        nombre = "",
        pagination = {},
        ordering = "nombre",
        area,
        departamento,
        sucursal,
      } = params;
      const { page = 1, pageSize = 1000 } = pagination;
      let url =
        URL_BASE +
        `/empleado/empleados-seleccionables/?ordering=${ordering}&search=${nombre}&page=${page}&page_size=${pageSize}`;
      if (area) url = url + `&area=${area}`;
      if (departamento) url = url + `&departamento=${departamento}`;
      if (sucursal) url = url + `&sucursal=${sucursal}`;
      const response = await axios.get(url, { headers });
      return response.data;
    },
  };
};
