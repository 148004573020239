import React, { useEffect } from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { commonActions } from "src/modules/common/handler/redux";

const EncuestaReportePage = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { encuestaId } = useParams();

  useEffect(() => {
    dispatch(commonActions.showSidebar(false));
    dispatch(commonActions.setTitle("Encuesta"));
  }, []);

  useEffect(() => {
    if (!encuestaId) return;
    dispatch(
      commonActions.setTitleMenu([
        {
          name: "General",
          to: `/encuesta/${encuestaId}/reporte/general`,
        },
        {
          name: "Resumen",
          to: `/encuesta/${encuestaId}/reporte/resumen`,
        },
      ])
    );
  }, [encuestaId]);

  const onBack = () => {
    navigate("/encuesta/");
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center gap-2 my-4">
        <button onClick={onBack}>
          <ArrowLeft size={24} />
        </button>
        <div className="flex w-2/3 justify-between px-4 border-b pb-2">
          <h1 className="text-xl font-bold">Reporte de encuestados</h1>
        </div>
        {/* Icono ficticio para igualar el espacio por izqiuerda y por derecha */}
        <ArrowLeft size={24} color="transparent" />{" "}
      </div>
      {children}
    </>
  );
};

export default EncuestaReportePage;
